import React, { useEffect } from "react";
import { Menu, MenuItem, ListItemText, Modal, useMediaQuery } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import {
    deleteOption,
    previewOption,
    shareOption,
    downloadOption,
    previewOptionActive,
    downloadOptionActive,
    shareOptionActive,
    deleteOptionActive,
    viewNotesIconInactive,
    annotateOptionActive,
    annotateOptionInactive,
    moveToIcon,
    moveToActive,
    deliverIcon,
    editIcon,
    deliverActive,
    editActiveIcon
} from "../../../assets";
import Notes from "../../../../../Notes/src/Notes.web";
import FolderList from "./FolderList.web";
import DeleteConfirmationModal from "./DeleteConfirmationModal.web";
import ShareLinkModal from "../../../SchoolAdmin/components/SelectedPractice/ShareLinkModal.web";
import AddFolderModal from "./AddFolderModal.web";
import NotForMobile from "../../../../../../components/src/NotForMobile.web";
const StyledTableItem = withStyles((theme) => ({
    root: {
        "&:hover": {
            backgroundImage:
                "linear-gradient(to right bottom, rgba(0,10,77,1) 0%, rgba(21,8,112,1) 36%, rgba(41,142,139,1) 100%)",
            "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
                color: theme.palette.common.white,
                fontFamily: "Poppins",
                fontSize: "16px",
            },
        },
    },
}))(MenuItem);
const StyledTable = withStyles({
    paper: {
        border: "1px solid #d3d4d5",
        boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
        borderRadius: "6px",
    },
})((props: any) => (
    <Menu
        elevation={0}
        getContentAnchorEl={null}
        anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
        }}
        transformOrigin={{
            vertical: "top",
            horizontal: "center",
        }}
        {...props}
    />
));
const TeacherCustomContextMenu = (props: any) => {
    const isSmallScreen = useMediaQuery('(max-width:600px)');
    const handleClick = () => {
        if (isSmallScreen && contextMenuData.itemType !== 'folder') {
            setLabel("Edit");
            handleOpenNoMobileModal();
        } else {
            if (contextMenuData.itemType !== "folder") {
                editPractice(contextMenuData.id, contextMenuData.name,contextMenuData.itemType);
            } else {
                handleOpenAddFolderModal(contextMenuData.name, "Edit", contextMenuData.id);
            }
        }
    };
    const {
        anchorEl,
        openContextMenu,
        handleCloseContextMenu,
        handleCloseAddNotesModal,
        handleOpenAddNotesModal,
        openAddNotesModal,
        contextMenuData,
        handleShowNotesIcon,
        noteData,
        openFolderList,
        handleOpenFolderList,
        handleCloseFolderList,
        folderAnchorEl,
        folderList,
        handleOpenMoveConfirmationPopUp,
        handleCloseMoveConfirmationPopUp,
        openMovePopup,
        moveLessonToFolder,
        handleSetFolderId,
        folderId,
        openMoveSuccessPopup,
        handleCloseSuccessPopup,
        handleOpenSuccessPopup,
        handleFolderNameSearchInput,
        folderSearchInput,
        loading,
        handleCloseDeleteModal,
        handleOpenDeleteModal,
        openDeleteConfirmationModal,
        deleteLessonFolder,
        moveOutLessonApi,
        showMoveOutOption,
        selectedLessons,
        handleOpenShareLinkModal,
        handleCloseShareLinkModal,
        openShareLinkModal,
        role,
        generateLink,
        shareLink,
        deliverLessonApi,
        downloadLesson,
        editPractice,
        openAddFolderModal,
        handleOpenAddFolderModal,
        handleCloseAddFolderModal,
        handleChangeFolderInput,
        folderName,
        updateLessonFolder,
        handleCloseNoMobileModal,
        handleOpenNoMobileModal,
        openNoMobileModal,
        currentLocation,
        handleOpenEditModal,
        viewMyLibraryLesson,
        viewMyLibraryAssignment,
        viewMyLibraryQuiz,
        selectedType
    } = props;
    const previewImageRef = React.useRef<any>();
    const deleteImageRef = React.useRef<any>();
    const shareImageRef = React.useRef<any>();
    const downloadImageRef = React.useRef<any>();
    const viewNotesImageRef = React.useRef<any>();
    const annotateImageRef = React.useRef<any>();
    const moveToImageRef = React.useRef<any>();
    const deliverImageRef = React.useRef<any>();
    const editImageRef = React.useRef<any>();
    const editImageRefDetails = React.useRef<any>();
    const moveIntoSubRef = React.useRef<any>();
    const [showIcon, setShowicon] = React.useState<boolean>(false)
    const [label, setLabel] = React.useState<string>('')

    useEffect(() => {
        const findId = noteData.find((obj: any) => obj === Number(contextMenuData.id))
        if(findId===undefined){
          setShowicon(true)
        }else {
          setShowicon(false)
        }
    }, [contextMenuData])


    const handleEditModalOpen = () =>{
        handleOpenEditModal()
        if(contextMenuData.itemType === 'lesson'){
            viewMyLibraryLesson(contextMenuData.id)
        }else if(contextMenuData.itemType === 'assignment'){
            viewMyLibraryAssignment(contextMenuData.id)
        }else{
            viewMyLibraryQuiz(contextMenuData.id)
        }
    }

    return (
        <>
            <StyledTable
                id="customized-menu"
                data-testid="customized-menu"
                anchorEl={anchorEl}
                open={openContextMenu}
                onClose={handleCloseContextMenu}
            >
                {contextMenuData.itemType!=="folder" && (<>
                    <StyledTableItem
                    data-test-id="preview-option"
                    onMouseEnter={(e) =>
                        previewImageRef.current && (previewImageRef.current.src = previewOptionActive)
                    }
                    onMouseLeave={(e) => previewImageRef.current && (previewImageRef.current.src = previewOption)}
                    onClick={() => isSmallScreen ? (setLabel("Preview"), handleOpenNoMobileModal()) : selectedLessons(contextMenuData.id, false,contextMenuData.itemType)}
                    >
                    <img
                        src={previewOption}
                        ref={previewImageRef}
                        style={{ marginRight: "10px" }}
                    />
                    <ListItemText primary="Preview" color="#48484A" />
                </StyledTableItem>
                {contextMenuData.itemType!=="assignment"&&(<StyledTableItem
                    data-test-id="deliver-option"
                    disabled={!contextMenuData.totalStage}
                    onMouseEnter={(e) =>
                        (deliverImageRef.current.src = deliverActive)
                    }
                    onMouseLeave={(e) => (deliverImageRef.current.src = deliverIcon)}
                    onClick={(e)=> isSmallScreen ? (setLabel("Deliver"), handleOpenNoMobileModal()) : deliverLessonApi(contextMenuData.id,contextMenuData.itemType)}
                >
                    <img
                        src={deliverIcon}
                        ref={deliverImageRef}
                        style={{ marginRight: "10px" }}
                    />
                    <ListItemText primary="Deliver" color="#48484A" />
                </StyledTableItem>)}
                <StyledTableItem
                    data-test-id="annotate-option"
                    onMouseEnter={(e) =>
                        (annotateImageRef.current.src = annotateOptionActive)
                    }
                    onMouseLeave={(e) => (annotateImageRef.current.src = annotateOptionInactive)}
                    onClick={()=> isSmallScreen ? (setLabel("Annotate"), handleOpenNoMobileModal()): selectedLessons(contextMenuData.id,true,contextMenuData.itemType)}
                >
                    <img
                        src={annotateOptionInactive}
                        ref={annotateImageRef}
                        style={{ marginRight: "10px" }}
                    />
                    <ListItemText primary="Annotate" color="#48484A" />
                </StyledTableItem>
                </>)}
                
                {showIcon && (
                    <StyledTableItem
                        data-test-id="add-notes"
                        onClick={(e) => {
                            handleOpenAddNotesModal("Add", contextMenuData.id, contextMenuData.name,contextMenuData.itemType)
                            handleCloseContextMenu(e);
                        }}
                        onMouseEnter={(e) =>
                            (viewNotesImageRef.current.src = viewNotesIconInactive)
                        }
                        onMouseLeave={(e) => (viewNotesImageRef.current.src = viewNotesIconInactive)}
                    >
                        <img
                            src={viewNotesIconInactive}
                            ref={viewNotesImageRef}
                            style={{ marginRight: "10px", width: "16px", height: "16px" }}
                        />
                        <ListItemText primary="Add Notes" color="#48484A" />
                    </StyledTableItem>
                )}
                 {contextMenuData.itemType !== "folder" && (<>
                <StyledTableItem
                    data-test-id="move-to"
                    onMouseEnter={(e) =>
                        (moveToImageRef.current.src = moveToActive)
                    }
                    onMouseLeave={(e) => (moveToImageRef.current.src = moveToIcon)}
                    onClick={(e)=>showMoveOutOption?moveOutLessonApi(contextMenuData.id,contextMenuData.itemType):handleOpenFolderList(e,"folder")}
                >
                    <img
                        src={moveToIcon}
                        ref={moveToImageRef}
                        style={{ marginRight: "10px" , transform: `${showMoveOutOption && "scaleX(-1)"}`}}
                    />
                    <ListItemText primary={showMoveOutOption?"Move out":"Move to"} color="#48484A" />
                </StyledTableItem>
                
                <StyledTableItem
                    data-test-id="download-option"
                    onMouseEnter={(e) =>
                        (downloadImageRef.current.src = downloadOptionActive)
                    }
                    onMouseLeave={(e) => (downloadImageRef.current.src = downloadOption)}
                    onClick={()=>{downloadLesson(contextMenuData.itemType,contextMenuData.id)}}
                >
                    <img
                        src={downloadOption}
                        ref={downloadImageRef}
                        style={{ marginRight: "10px" }}
                    />
                    <ListItemText primary="Download" color="#48484A" />
                </StyledTableItem>
                </>)}
                {(contextMenuData.itemType !== "folder" && contextMenuData.shared) &&  (<>
                 <StyledTableItem
                    onMouseEnter={(e) => (shareImageRef.current.src = shareOptionActive)}
                    onMouseLeave={(e) => (shareImageRef.current.src = shareOption)}
                    onClick={(e) => {
                        generateLink(contextMenuData.id)
                        handleOpenShareLinkModal();
                        handleCloseContextMenu(e);
                      }}
                >
                    <img
                        src={shareOption}
                        ref={shareImageRef}
                        style={{ marginRight: "10px" }}
                    />
                    <ListItemText primary="Share" color="#48484A" />
                </StyledTableItem>
                </>)}
                <StyledTableItem
                    data-test-id="edit-option"
                    onMouseEnter={(e) =>
                        (editImageRef.current.src = editActiveIcon)
                    }
                    onMouseLeave={(e) => (editImageRef.current.src = editIcon)}
                    onClick={()=>handleClick()}
                >
                    <img
                        src={editIcon}
                        ref={editImageRef}
                        style={{ marginRight: "10px" }}
                    />
                    <ListItemText primary={contextMenuData.itemType !== "folder"?"Edit Contents":"Rename"} color="#48484A" />
                </StyledTableItem>
                {contextMenuData.itemType !== "folder"&&<StyledTableItem
                    data-test-id="editOptionFile"
                    onMouseEnter={(e) =>
                        (editImageRefDetails.current.src = editActiveIcon)
                    }
                    onMouseLeave={(e) => (editImageRefDetails.current.src = editIcon)}
                    onClick={()=>handleEditModalOpen()}
                >
                    <img
                        src={editIcon}
                        ref={editImageRefDetails}
                        style={{ marginRight: "10px" }}
                    />
                    <ListItemText primary={`Edit`} color="#48484A"  />
                </StyledTableItem>}
                <StyledTableItem
                    data-test-id="open-delete-modal"
                    onMouseEnter={(e) =>
                        (deleteImageRef.current.src = deleteOptionActive)
                    }
                    onMouseLeave={(e) => (deleteImageRef.current.src = deleteOption)}
                    onClick={(e) => {
                        handleOpenDeleteModal();
                        handleCloseContextMenu(e);
                      }}
                >
                    <img
                        src={deleteOption}
                        ref={deleteImageRef}
                        style={{ marginRight: "10px" }}
                    />
                    <ListItemText primary="Delete" color="#48484A" />
                </StyledTableItem>
                {contextMenuData?.type === "subFolder" && <StyledTableItem
                    data-test-id="move-into-subfolder"
                    onMouseEnter={(e) =>
                        (moveIntoSubRef.current.src = moveToActive)
                    }
                    onMouseLeave={(e) => (moveIntoSubRef.current.src = moveToIcon)}
                    onClick={(e) => {handleOpenFolderList(e,"subFolder")}}
                >
                    <img
                        src={moveToIcon}
                        ref={moveIntoSubRef}
                        style={{ marginRight: "10px" }}
                    />
                    <ListItemText primary="Move to" color="#48484A" />
                </StyledTableItem>}
            </StyledTable>
            <Notes
                id=""
                navigation={undefined}
                handleCloseModal={handleCloseAddNotesModal}
                open={openAddNotesModal}
                handleShowNotesIcon={handleShowNotesIcon}
                selectedType={selectedType}
            />
              <FolderList
            anchorEl={folderAnchorEl}
            open={openFolderList}
            onClose={handleCloseFolderList}
            folderList={folderList}
            handleOpenMoveConfirmationPopUp={handleOpenMoveConfirmationPopUp}
            handleCloseMoveConfirmationPopUp={handleCloseMoveConfirmationPopUp}
            openMovePopup={openMovePopup}
            contextMenuData={contextMenuData}
            moveLessonToFolder={moveLessonToFolder}
            handleSetFolderId={handleSetFolderId}
            folderId={folderId}
            openMoveSuccessPopup={openMoveSuccessPopup}
            handleCloseSuccessPopup={handleCloseSuccessPopup}
            handleOpenSuccessPopup={handleOpenSuccessPopup}
            folderSearchInput={folderSearchInput}
            handleFolderNameSearchInput={handleFolderNameSearchInput}
            loading={loading}
            />

            <Modal
             data-testid="delete-modal"
             open={openDeleteConfirmationModal}
             onClose={handleCloseDeleteModal}
            >
             <DeleteConfirmationModal
              handleCloseModal={handleCloseDeleteModal}
              contextMenuData={contextMenuData}
              deleteLessonFolder={deleteLessonFolder}
            />
            </Modal>
            <Modal
             data-testid="nomobile-modal"
             open={openNoMobileModal}
             onClose={handleCloseNoMobileModal}
            >
             <NotForMobile
             moduleName={label}
              handleCloseModal={handleCloseNoMobileModal}
            />
            </Modal>
            <Modal
        data-testid="share-modal"
        open={openShareLinkModal}
        onClose={handleCloseShareLinkModal}
      >
        <ShareLinkModal handleCloseModal={handleCloseShareLinkModal} role={role} contextMenuData={contextMenuData}shareLink={shareLink} />
      </Modal>
      <Modal open={openAddFolderModal} onClose={handleCloseAddFolderModal}>
        <AddFolderModal 
         data-test-id="folder-modal"
         handleCloseAddFolderModal={handleCloseAddFolderModal}
         handleChangeFolderInput={handleChangeFolderInput}
         createLessonFolder={updateLessonFolder}
         folderName={folderName}
         />
        </Modal>
        </>
    );
};

export default TeacherCustomContextMenu;
