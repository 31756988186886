import React from "react";
import {
  Box,
  Grid
} from "@material-ui/core";
import CatalogueController, { Props } from "../CatalogueController";
import Sidebar from "../../../../components/src/Sidebar.web";
import AppHeaderWeb from "../../../../components/src/AppHeader.web";
import ClassListPage from "./components/ClassList/ClassListPage.web";
import SelectedClass from "../SchoolAdmin/components/SelectedClass/SelectedClass.web";
import TeacherSelectedPractice from "./components/TeacherSelectedPractice/TeacherSelectedPractice.web";
import { TEACHER_Library_VIEWS } from "../../../../components/src/types";
import SelectedFolderView from "./components/TeacherSelectedPractice/SelectedFolderView.web";
import LessonPreviewPage from "./components/TeacherSelectedPractice/LessonPreviewPage.web";
import SharedSelectedPractice from "./components/SharedSelectedPractice.web";
import { SuccessSnackbar } from "../../../../components/src/CustomSuccessPopup.web";

export default class TeacherCatalogue extends CatalogueController {
  constructor(props: Props) {
    super(props);
    this.handleSearchInput = this.handleSearchInput.bind(this);
    this.selectedPracticeForTeacherRole = this.selectedPracticeForTeacherRole.bind(this)
    this.redirectForTeacherRole = this.redirectForTeacherRole.bind(this)
    this.handleTeacherClassPageChange = this.handleTeacherClassPageChange.bind(this)
    this.selectedPracticeForTeacher= this.selectedPracticeForTeacher.bind(this)
    this.handleClassSearchInput = this.handleClassSearchInput.bind(this)
    this.handleLessonPageChange = this.handleLessonPageChange.bind(this);
    this.handleSearchInputTableForTeacher = this.handleSearchInputTableForTeacher.bind(this);
    this.handleShowNotesIcon = this.handleShowNotesIcon.bind(this);
    this.handleOpenAddFolderModal = this.handleOpenAddFolderModal.bind(this);
    this.handleCloseAddFolderModal = this.handleCloseAddFolderModal.bind(this);
    this.handleChangeFolderInput =  this.handleChangeFolderInput.bind(this);
    this.addEditFolder = this.addEditFolder.bind(this);
    this.handleOpenFolderList = this.handleOpenFolderList.bind(this);
    this.handleCloseFolderList= this.handleCloseFolderList.bind(this);
    this.handleOpenMoveConfirmationPopUp=this.handleOpenMoveConfirmationPopUp.bind(this);
    this.handleCloseMoveConfirmationPopUp=this.handleCloseMoveConfirmationPopUp.bind(this);
    this.moveLessonToFolder=this.moveLessonToFolder.bind(this);
    this.handleSetFolderId=this.handleSetFolderId.bind(this);
    this.handleOpenSuccessPopup=this.handleOpenSuccessPopup.bind(this);
    this.handleCloseSuccessPopup=this.handleCloseSuccessPopup.bind(this);
    this.handleFolderNameSearchInput=this.handleFolderNameSearchInput.bind(this);
    this.deleteLessonFolder=this.deleteLessonFolder.bind(this)
    this.showLessonsForSelectedFolder=this.showLessonsForSelectedFolder.bind(this);
    this.moveOutLessonApi=this.moveOutLessonApi.bind(this)
    this.handleOpenDeleteModal=this.handleOpenDeleteModal.bind(this)
    this.handleCloseDeleteModal=this.handleCloseDeleteModal.bind(this)
    this.handleShowFolderPageChange=this.handleShowFolderPageChange.bind(this)
    this.handleFolderLessonSearchInput=this.handleFolderLessonSearchInput.bind(this)
    this.selectedLessons=this.selectedLessons.bind(this);
    this.nextStage=this.nextStage.bind(this);
    this.prevStage=this.prevStage.bind(this);
    this.generateLink=this.generateLink.bind(this)
    this.selectedSharedPractice=this.selectedSharedPractice.bind(this)
    this.handleSharedSearchInputTableForTeacher=this.handleSharedSearchInputTableForTeacher.bind(this)
    this.handleSharedtablePageChange=this.handleSharedtablePageChange.bind(this)
    this.handleHighlightText=this.handleHighlightText.bind(this)
    this.deliverPractice=this.deliverPractice.bind(this)
    this.downloadPractice=this.downloadPractice.bind(this)
    this.editPractice=this.editPractice.bind(this)
    this.handleOpenAnnotationConfirmation=this.handleOpenAnnotationConfirmation.bind(this)
    this.handleCloseAnnotationConfirmation=this.handleCloseAnnotationConfirmation.bind(this)
    this.handleHighlightedTextData=this.handleHighlightedTextData.bind(this)
    this.applyHighlightDirectly=this.applyHighlightDirectly.bind(this)
    this.redoHighlight=this.redoHighlight.bind(this)
    this.handleCloseDeleteSuccess=this.handleCloseDeleteSuccess.bind(this)
    this.getCommentData=this.getCommentData.bind(this)
    this.removeCommentIcon=this.removeCommentIcon.bind(this);
    this.enableLimitSnackbar=this.enableLimitSnackbar.bind(this)
    this.showLessonsForSelectedSubFolder=this.showLessonsForSelectedSubFolder.bind(this)
    this.handleShowSubFolderPageChange=this.handleShowSubFolderPageChange.bind(this)
  }

  renderSwitch(selectedOption: string) {
    switch (selectedOption) {
        case "Selected Class":
            return (
              <SelectedClass
                callback={this.selectedPracticeForTeacher}
                currentLocation={this.state.currentLocation}
                locations={this.locations}
                redirect={this.redirectForTeacherRole}
                role={this.state.role}
              />
            );

            case "Selected Practice":
              return (
                <TeacherSelectedPractice
                  currentLocation={this.state.currentLocation}
                  locations={this.locations}
                  practiceData={this.state.practiceData}
                  tableSearchInput={this.state.tableSearchInput}
                  redirect={this.redirectForTeacherRole}
                  loading={this.state.loading}
                  handleLessonPageChange={this.handleLessonPageChange}
                  pageNoPractice={this.state.pageNoPractice}
                  pageLimitPractice={this.state.pageLimitPractice}
                  handleSearchInputTableForTeacher={this.handleSearchInputTableForTeacher}
                  handleShowNotesIcon={this.handleShowNotesIcon}
                  notesList={this.state.notesList}
                  openAddFolderModal={this.state.openAddFolderModal}
                  handleOpenAddFolderModal={this.handleOpenAddFolderModal}
                  handleCloseAddFolderModal={this.handleCloseAddFolderModal}
                  handleChangeFolderInput={this.handleChangeFolderInput}
                  createLessonFolder={this.addEditFolder}
                  folderName={this.state.folderName}
                  openFolderList={this.state.openFolderList}
                  handleOpenFolderList={this.handleOpenFolderList}
                  handleCloseFolderList={this.handleCloseFolderList}
                  folderAnchorEl={this.state.folderAnchorEl}
                  folderList={this.state.folderList}
                  handleOpenMoveConfirmationPopUp={this.handleOpenMoveConfirmationPopUp}
                  handleCloseMoveConfirmationPopUp={this.handleCloseMoveConfirmationPopUp}
                  openMovePopup={this.state.openMoveConfirmationPopup}
                  moveLessonToFolder={this.moveLessonToFolder}
                  handleSetFolderId={this.handleSetFolderId}
                  folderId={this.state.folderId}
                  handleCloseSuccessPopup={this.handleCloseSuccessPopup}
                  handleOpenSuccessPopup={this.handleOpenSuccessPopup}
                  openMoveSuccessPopup={this.state.openMoveSuccessPopup}
                  handleFolderNameSearchInput={this.handleFolderNameSearchInput}
                  folderSearchInput={this.state.folderSearchInput}
                  isLoading={this.state.isLoading}
                  deleteLessonFolder={this.deleteLessonFolder}
                  showLessonsForSelectedFolder={this.showLessonsForSelectedFolder}
                  handleOpenDeleteModal={this.handleOpenDeleteModal}
                  handleCloseDeleteModal={this.handleCloseDeleteModal}
                  openDeleteConfirmationModal={this.state.openDeleteConfirmationModal}
                  handleOpenNoMobileModal={this.handleOpenNoMobileModal}
                  handleCloseNoMobileModal={this.handleCloseNoMobileModal}
                  openNoMobileModal={this.state.openNoMobileModal}
                  selectedLessons={this.selectedLessons}
                  role={this.state.role}
                  generateLink={this.generateLink}
                  shareLink={this.state.shareLink}
                  deliverLessonApi={this.deliverPractice}
                  classList={this.state.classes}
                  downloadLesson={this.downloadPractice}
                  editPractice={this.editPractice}
                  openSuccessBar={this.state.openSuccessBar}
                  handleCloseDeleteSuccess={this.handleCloseDeleteSuccess}
                  successBarData={this.state.successBarData}
                  editFuncMyLibrary={this.editFuncMyLibraryLesson}
                  viewMyLibraryLesson={this.viewMyLibraryLesson}
                  viewMyLibraryAssignment={this.viewMyLibraryAssignment}
                  viewMyLibraryQuiz={this.viewMyLibraryQuiz}
                  showLessons={this.state.showLessons}
                  editFuncMyLibraryLesson={this.editFuncMyLibraryLesson}
                  editFuncMyLibraryAssignment={this.editFuncMyLibraryAssignment}
                  handleLessonNameChange={this.handleLessonNameChange}
                  editFuncMyLibraryQuiz={this.editFuncMyLibraryQuiz}
                  state={this.state}
                  handleEditClassChange={this.handleEditClassChange}
                  handleEditStartDateChange={this.handleEditStartDateChange}
                  handleEditDueDateChange={this.handleEditDueDateChange}
                  handleLessonObjectiveChange={this.handleLessonObjectiveChange}
                  handleCloseEditModal={this.handleCloseEditModal}
                  handleOpenEditModal={this.handleOpenEditModal}
                  handleEditUploadIcon={this.handleEditUploadIcon}
                  isSubFolder={this.state.isSubFolder}
                  selectedType={this.state.selectedType}
                />
              );  
              case "Selected folder":
                return(
                  <SelectedFolderView
                  locations={this.locations}
                  currentLocation={this.state.currentLocation}
                  redirect={this.redirectForTeacherRole}
                  lessons={this.state.lessons}
                  loading={this.state.loading}
                  isLoading={this.state.isLoading}
                  moveOutLessonApi={this.moveOutLessonApi}
                  deleteLessonFolder={this.deleteLessonFolder}
                  handleOpenDeleteModal={this.handleOpenDeleteModal}
                  handleCloseDeleteModal={this.handleCloseDeleteModal}
                  openDeleteConfirmationModal={this.state.openDeleteConfirmationModal}
                  pageNoShowFolderLimit={this.state.pageNoShowFolderLimit}
                  handleShowFolderPageChange={this.handleShowFolderPageChange}
                  showFolderLessonSearch={this.state.showFolderLessonSearch}
                  handleShowNotesIcon={this.handleShowNotesIcon}
                  pageNoShowFolder={this.state.pageNoShowFolder}
                  handleFolderLessonSearchInput={this.handleFolderLessonSearchInput}
                  notesList={this.state.notesList}
                  showMoveOutOption={this.state.showMoveOutOption}
                  selectedLessons={this.selectedLessons}
                  handleOpenAddFolderModal={this.handleOpenAddFolderModal}
                  openAddFolderModal={this.state.openAddFolderModal}
                  handleCloseAddFolderModal={this.handleCloseAddFolderModal}
                  handleChangeFolderInput={this.handleChangeFolderInput}
                  createLessonFolder={this.addEditFolder}
                  folderName={this.state.folderName}
                  showLessonsForSelectedSubFolder={this.showLessonsForSelectedSubFolder}
                  moveLessonToFolder={this.moveLessonToFolder}
                  downloadLesson={this.downloadPractice}
                  handleEditClassChange={this.handleEditClassChange}
                  handleEditStartDateChange={this.handleEditStartDateChange}
                  handleEditDueDateChange={this.handleEditDueDateChange}
                  editPractice={this.editPractice}
                  viewMyLibraryLesson={this.viewMyLibraryLesson}
                  handleOpenEditModal={this.handleOpenEditModal}
                  state={this.state}
                  handleLessonObjectiveChange={this.handleLessonObjectiveChange}
                  editFuncMyLibraryLesson={this.editFuncMyLibraryLesson}
                  editFuncMyLibraryAssignment={this.editFuncMyLibraryAssignment}
                  handleLessonNameChange={this.handleLessonNameChange}
                  handleCloseEditModal={this.handleCloseEditModal}
                  editFuncMyLibraryQuiz={this.editFuncMyLibraryQuiz}
                  showLessons={this.state.showLessons}
                  deliverLessonApi={this.deliverPractice}
                  handleOpenFolderList={this.handleOpenFolderList}
                  folderList={this.state.folderList}
                  openFolderList={this.state.openFolderList}
                  handleCloseFolderList={this.handleCloseFolderList}
                  folderAnchorEl={this.state.folderAnchorEl}
                  isSubFolder={this.state.isSubFolder}
                  handleSetFolderId={this.handleSetFolderId}
                  handleOpenMoveConfirmationPopUp={this.handleOpenMoveConfirmationPopUp}
                  openMovePopup={this.state.openMoveConfirmationPopup}
                  handleCloseMoveConfirmationPopUp={this.handleCloseMoveConfirmationPopUp}
                  handleFolderNameSearchInput={this.handleFolderNameSearchInput}
                  folderSearchInput={this.state.folderSearchInput}
                  render={this.state.render}
                  handleCloseSuccessPopup={this.handleCloseSuccessPopup}
                  handleOpenSuccessPopup={this.handleOpenSuccessPopup}
                  openMoveSuccessPopup={this.state.openMoveSuccessPopup}
                  viewMyLibraryQuiz={this.viewMyLibraryQuiz}
                  viewMyLibraryAssignment={this.viewMyLibraryAssignment}
                  />
                )
                case "Selected Lesson":
                  return(
                    <LessonPreviewPage
                    currentLocation={this.state.currentLocation}
                    locations={this.locations}
                    redirect={this.redirectForTeacherRole}
                    stageType={this.state.stageType}
                    nextStage={this.nextStage}
                    prevStage={this.prevStage}
                    currentStage={this.state.currentStage}
                    loading={this.state.loading}
                    isAnnotate={this.state.isAnnotate}
                    handleHighlightText={this.handleHighlightText}
                    handleOpenAnnotationConfirmation={this.handleOpenAnnotationConfirmation}
                    handleCloseAnnotationConfirmation={this.handleCloseAnnotationConfirmation}
                    openAnnotationConfirmation={this.state.openAnnotationConfirmation}
                    handleCloseSuccessPopup={this.handleCloseSuccessPopup}
                    handleOpenSuccessPopup={this.handleOpenSuccessPopup}
                    openMoveSuccessPopup={this.state.openMoveSuccessPopup}
                    highlights={this.state.highlights}
                    handleHighlightedTextData={this.handleHighlightedTextData}
                    highlightedData={this.state.highlightedData}
                    applyHighlightDirectly={this.applyHighlightDirectly}
                    redoHighlight={this.redoHighlight}
                    commentData={this.state.commentData}
                    getCommentData={this.getCommentData}
                    removeCommentIcon={this.removeCommentIcon}
                    stages={this.state.stages}
                    stagesData={this.state.stagesData}
                    handleSaveClick={this.handleSaveClick}
                    checkSignIn={this.handleAuthClick}
                    openFileUploadSuccess={this.state.openFileUploadSuccess}
                    handleCloseGdriveModal={this.handleCloseGdriveModal}
                    uploading={this.state.uploading}
                    enableLimitSnackbar={this.enableLimitSnackbar}
                    />
                  )
                  case "SharedSelectedPractice":
                    return(
                      <SharedSelectedPractice
                      currentLocation={this.state.currentLocation}
                      locations={this.locations}
                      redirect={this.redirectForTeacherRole}
                      sharedData={this.state.sharedData}
                      tableSearchInput={this.state.tableSearchInput}
                      handleSharedSearchInputTableForTeacher={this.handleSharedSearchInputTableForTeacher}
                      pageLimitPractice={this.state.pageLimitPractice}
                      handleSharedtablePageChange={this.handleSharedtablePageChange}
                      pageNoPractice={this.state.pageNoPractice}
                      loading={this.state.loading}
                      downloadLesson={this.downloadPractice}
                      selectedLessons={this.selectedLessons}
                      />
                    )
                    case "Classes":
                      return (
                        <ClassListPage
                          searchInput={this.state.searchInput}
                          classList={this.state.classes}
                          handleSearchInput={this.handleClassSearchInput}
                          callback={this.selectedPracticeForTeacherRole}
                          pageLimit={this.state.pageNoTeacherClassesLimit}
                          pageNo={this.state.pageNoTeacherClasses}
                          handleTeacherClassPageChange={this.handleTeacherClassPageChange}
                          loading={this.state.loading}
                          role={this.state.role}
                          selectedSharedPractice={this.selectedSharedPractice}
                          selectedTab={this.state.selectedTab}
                          navigation={this.props.navigation}
                        />
                      );
                      case "Selected subFolder":
                       
                        return(
                          <SelectedFolderView
                          locations={this.locations}
                          currentLocation={this.state.currentLocation}
                          redirect={this.redirectForTeacherRole}
                          lessons={this.state.subFolderData}
                          loading={this.state.loading}
                          isLoading={this.state.isLoading}
                          moveOutLessonApi={this.moveOutLessonApi}
                          deleteLessonFolder={this.deleteLessonFolder}
                          handleOpenDeleteModal={this.handleOpenDeleteModal}
                          handleCloseDeleteModal={this.handleCloseDeleteModal}
                          openDeleteConfirmationModal={this.state.openDeleteConfirmationModal}
                          pageNoShowFolderLimit={this.state.pageNoShowSubFolderLimit}
                          handleShowFolderPageChange={this.handleShowSubFolderPageChange}
                          pageNoShowFolder={this.state.pageNoShowSubFolder}
                          handleFolderLessonSearchInput={this.handleFolderLessonSearchInput}
                          showFolderLessonSearch={this.state.showFolderLessonSearch}
                          handleShowNotesIcon={this.handleShowNotesIcon}
                          notesList={this.state.notesList}
                          showMoveOutOption={this.state.showMoveOutOption}
                          downloadLesson={this.downloadPractice}
                          editPractice={this.editPractice}
                          viewMyLibraryLesson={this.viewMyLibraryLesson}
                          handleOpenEditModal={this.handleOpenEditModal}
                          state={this.state}
                          handleEditClassChange={this.handleEditClassChange}
                          handleEditStartDateChange={this.handleEditStartDateChange}
                          handleEditDueDateChange={this.handleEditDueDateChange}
                          handleLessonObjectiveChange={this.handleLessonObjectiveChange}
                          handleCloseEditModal={this.handleCloseEditModal}
                          editFuncMyLibraryLesson={this.editFuncMyLibraryLesson}
                          editFuncMyLibraryAssignment={this.editFuncMyLibraryAssignment}
                          handleLessonNameChange={this.handleLessonNameChange}
                          editFuncMyLibraryQuiz={this.editFuncMyLibraryQuiz}
                          showLessons={this.state.showLessons}
                          deliverLessonApi={this.deliverPractice}
                          selectedLessons={this.selectedLessons}
                          handleOpenAddFolderModal={this.handleOpenAddFolderModal}
                          openAddFolderModal={this.state.openAddFolderModal}
                          handleCloseAddFolderModal={this.handleCloseAddFolderModal}
                          handleChangeFolderInput={this.handleChangeFolderInput}
                          createLessonFolder={this.addEditFolder}
                          folderName={this.state.folderName}
                          showLessonsForSelectedSubFolder={this.showLessonsForSelectedSubFolder}
                          render={this.state.render}
                          viewMyLibraryQuiz={this.viewMyLibraryQuiz}
                          viewMyLibraryAssignment={this.viewMyLibraryAssignment}
                          />
                        )
      default:
        return (
          <></>
        );
      }}
  render() {
    return (
      <Box>
        <SuccessSnackbar
          message={'Your 2GB storage limit has been reached. Please delete few files to refresh your limit.'} 
          open={this.state.isLimitModalOpen} 
          onClose={this.handleCloseLimitSnackbar} 
          type={'error'}
        />
        <Grid  container={this.state.isSmallScreen ? false : true}  style = {{ overflowX: "hidden", height: "100%" }}>
          <Grid  item {...(!this.state.isSmallScreen && { xs: 2 })}>
          {!this.state.isSmallScreen && <Sidebar />}
          </Grid>
          <Grid  item {...(!this.state.isSmallScreen && { xs: 10 })} style={{ overflowY: "scroll", height: "100%" }}>
            <Grid  item md={12}>
              <AppHeaderWeb  title={TEACHER_Library_VIEWS.LIBRARY} />
            </Grid>
            <Grid item md = {12}>
               {this.renderSwitch(this.state.render)}
            </Grid>
          </Grid>
        </Grid>
      </Box>
    );
  }
}
