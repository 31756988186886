import React from "react";
import { Box, Typography, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import ClearIcon from "@material-ui/icons/Clear";
const configJSON = require("../config");
import { deleteProfileImage, deleteProfileSuccessImage } from "../assets";
const useStyles = makeStyles((theme) => ({
  modalContent: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  container: {
    position: "absolute",
    width: "488px",
    backgroundColor: theme.palette.background.paper,
    borderRadius: "10px",
    boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
    display: "flex",
    justifyContent: "center",
    aignItems: "center",
    padding: "35px 0",
    "@media (max-width: 600px)": {
      width:"90%"
    },
  },
  
  modalSubtitle: {
    fontFamily: "Poppins",
    fontSize: "18px",
    color: "#48484A",
    fontWeight: 400,
    width: "390px",
    textAlign: "center",
  },
  modalTitle: {
    fontFamily: "Poppins",
    fontSize: "20px",
    color: "#2C2C2E",
    fontWeight: 600,
    marginBottom: "15px",
    "@media (max-width: 430px)": {
      width:"80%",
      textAlign:"center"
    },
  },
  innerContainer: {
    positiobe: "relative",
  },
  modalImage: {
    marginBottom: "15px",
  },
 
  actionButtonLayout: {
    display: "flex",
    paddingTop: "30px",
    justifyContent: "end",
    paddingBotton: "10px",
    "@media (max-width: 430px)": {
      width:"70%"
    },
  },
  cancelIcon: {
    position: "absolute",
    top: "10px",
    right: "10px",
  },
  
  normalBtn: {
    borderRadius: "10px",
    width: "154px",
    padding: "12px",
    textTransform: "none",
    border: "1px solid #2B3D7D",
    color: "#3F526D",
    fontFamily: "Poppins",
    fontSize: "18px",
  },
  coloredBtn: {
    backgroundImage:
      "linear-gradient(to right bottom, rgba(0,10,77,1) 0%, rgba(21,8,112,1) 36%, rgba(41,142,139,1) 100%)",
    color: "#FFF",
    borderRadius: "10px",
    width: "154px",
    padding: "12px",
    textTransform: "none",
    fontFamily: "Poppins",
    fontSize: "18px",
    marginLeft: "20px",
  },
  error: {
    fontFamily: "Poppins",
    fontSize: "12px",
    color: "#FE575A",
    fontWeight: 400,
  },
}));
function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}
const DeleteProfileModal = (props: any) => {
  const {
    handleCloseModal,
    deleteProfile,
    userData,
    handleCloseContextMenu,
    role,
    isDeleteSuccess,
    deleteProfileError,
  } = props;
  const [modalStyle] = React.useState(getModalStyle);
  const handleDelete = (event: any) => {
    deleteProfile(userData.id, role);
    handleCloseContextMenu(event);
  };
  const classes = useStyles();
  const renderModalTitile = () => {
    return (
      <Typography className={classes.modalTitle}>
        {!isDeleteSuccess
          ? configJSON.deleteProfile[role].title
          : configJSON.deleteProfileSuccess[role].title}
      </Typography>
    );
  };
  const renderModalSubTitle = () => {
    if (!isDeleteSuccess) {
      return (
        <Typography className={classes.modalTitle}>
          "{userData.name}" {configJSON.deleteProfile[role].subTitle}
        </Typography>
      );
    } else {
      return (
        <Typography className={classes.modalSubtitle}>
          {configJSON.deleteProfileSuccess[role].subTitle} "{userData.name}"
        </Typography>
      );
    }
  };
  return (
    <Box style={modalStyle} className={classes.container}>
      <Box className={classes.innerContainer}>
        <ClearIcon onClick={handleCloseModal} className={classes.cancelIcon} />
        <Box className={classes.modalContent}>
          <img
            className={classes.modalImage}
            src={
              !isDeleteSuccess ? deleteProfileImage : deleteProfileSuccessImage
            }
          />
          {renderModalTitile()}
          {renderModalSubTitle()}
          {deleteProfileError && (
            <Typography className={classes.error}>
              {configJSON.deleteProfileError}
            </Typography>
          )}
          {!isDeleteSuccess && (
            <Box className={classes.actionButtonLayout}>
              <Button onClick={handleCloseModal} className={classes.normalBtn}>
                Cancel
              </Button>
              {!deleteProfileError && (
                <Button
                  onClick={(e) => handleDelete(e)}
                  data-testid="delete-profile-btn"
                  className={classes.coloredBtn}
                >
                  Delete
                </Button>
              )}
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default DeleteProfileModal;
