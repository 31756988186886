import React from 'react'
import {
  Box,
  IconButton,
  Modal,
  Grid
} from "@material-ui/core";
import VisibilityOffOutlinedIcon from "@material-ui/icons/VisibilityOffOutlined";
import VisibilityOutlinedIcon from "@material-ui/icons/VisibilityOutlined";
import { Formik,
  Form,
} from "formik";
import * as yup from "yup";
import {Error, Success, SuccessImage, ErrorImage, CloseIcon} from "../assets"
import CustomisableUserProfilesController, { Props } from '../CustomisableUserProfilesController';
import { setTimeout } from 'timers';
import CircularProgress from '@material-ui/core/CircularProgress';

const gradientBackground = "linear-gradient(to right bottom, #2B3D7D, #39C6B3)";
type PositionRelative = 'relative';
type PositionStyle = 'absolute';
type TextAlignProperty = 'center';

interface PasswordInputProps {
  label: string;
  name: string;
  value: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  enablePasswordField : boolean;
  handleClickShowPassword: any;
  error?: boolean;
  placeholder?: string;
  isSuccess?: boolean;
}

interface CommonButtonsProps {
  error?: boolean;
  handleCancel?: any;
  handleChangePassword?: any;
  values: {
    old_password: string,
    new_password : string,
    confirm_new_password : string,
  }
}

interface ConfirmPasswordMessagesProps {
  errors: any,
  values: {
    old_password: string,
    new_password : string,
    confirm_new_password : string,
  }
}

const webStyles = {
  loaderStyle: {
    display: "flex",
    width: "100%",
    height: "60%",
    justifyContent: "center",
    alignItems: "center",
  },
  inputLabel:{
    fontFamily: "Poppins",
    fontSize: "14px",
    fontWeight: 600,
    color: "#444444"
  },
  inputLabelError:{
    fontFamily: "Poppins",
    fontSize: "14px",
    fontWeight: 600,
    color: "#fe575a"
  },
  inputLabelSuccess:{
    fontFamily: "Poppins",
    fontSize: "14px",
    fontWeight: 600,
    color: "#108336"
  },
  custominputPlaceholder: {
    borderRadius: "8px",
    border: "1px solid #C0C0C0",
    padding: "8px 15px",
    marginTop: "10px",
    display: "block",
    width: "100%",
    minHeight: "50px",
    fontFamily: "Poppins",
    fontSize: "18px",
    color: "#3f526d",
  },
  custominput: {
    borderRadius: "8px",
    border: "1px solid #C0C0C0",
    padding: "8px 15px",
    marginTop: "10px",
    display: "block",
    width: "100%",
    minHeight: "50px",
    fontSize: "16px",    
  },
  custominputSuccess: {
    borderRadius: "8px",
    border: "1px solid  #108336",
    padding: "8px 15px",
    marginTop: "10px",
    display: "block",
    width: "100%",
    minHeight: "50px",
    fontSize: "16px",    
  },
  error:{
    color: "#fe575a",
    fontSize: "12px",
    display: "flex",
    fontFamily: "Poppins",
    alignItems: "center",
    fontWeight: 400, 
    gap: "10px"
  },
  successMessage:{
    color: "#108336",
    fontSize: "12px",
    display: "flex",
    fontFamily: "Poppins",
    alignItems: "center",
    fontWeight: 400, 
    gap: "10px"
  },
  passwordInput:{
    width: '100%',
    position: "relative" as PositionRelative
  },
  iconBtn:{
    position: "absolute" as PositionStyle,
    top: "35px",
    right: "25px"
  },
  cancelsBtn: {
    border: "1px solid #2B337D",
    backgroundColor: "#fff",
    borderRadius: "5px",
    color: "#000",
    width: "100px",
    height: "50px",
    fontFamily: "Poppins",
    fontSize: "14px",
    fontWeight: 400,
    marginRight: "15px",
    cursor: "pointer"
  },
  passwordDoneBtn: {
    background: gradientBackground,
    border: "none",
    borderRadius: "5px",
    color: "#fff",
    width: "100px",
    height: "50px",
    fontFamily: "Poppins",
    fontSize: "14px",
    fontWeight: 400,
    cursor: "pointer"
  },
  passwordDisableBtn: {
    background: gradientBackground,
    border: "none",
    borderRadius: "5px",
    color: "#fff",
    width: "100px",
    height: "50px",
    fontFamily: "Poppins",
    fontSize: "14px",
    fontWeight: 400,
    cursor: "not-allowed",
    opacity: 0.4
  },
  modalsStyles: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: "12px",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    minWidth: 600,
    bgcolor: "background.paper",
    border: "none",
    boxShadow: 24,
    borderRadius: "16px",
    p: 4,
  },
  closeIcon:{
    position: "absolute" as PositionStyle,
    right: "20px",
    top: "20px",
    cursor:"pointer"
  },
  modalHeading: {
    color: "#2c2c2e",
    fontFamily: "Poppins",
    fontSize: "20px",
    fontWeight: 600
  }, 
  modalSubHeading: {
    color: "#48484a",
    fontFamily: "Poppins",
    fontSize: "18px",
    fontWeight: 400,
    margin: "10px 100px 20px",
    textAlign: "center" as TextAlignProperty
  },
  inputWrapper : {
   padding: "50px 40px",
   "@media (max-width: 680px)": {
      padding:"50px 17px"
    },
  },
  cmnBtnLayout:{
    display:"flex",
    justifyContent:"end",
    position:"relative",
    bottom:"20",
    right:"20",
    "@media (max-width: 600px)": {
      right:0,
      bottom:0,
      justifyContent:"center"
    },
  }
  
 }

export const PasswordInput: React.FC<PasswordInputProps> = ({ label, name, value, onChange, enablePasswordField, handleClickShowPassword, error, placeholder, isSuccess }: PasswordInputProps) => { 
  const success = isSuccess ? webStyles.inputLabelSuccess : webStyles.inputLabel
  const successInput = isSuccess ? webStyles.custominputSuccess : webStyles.custominput
  return <div style={webStyles.passwordInput}>
    <label style={ error ? webStyles.inputLabelError : success}>{label}</label>
    <input
      type={enablePasswordField ? "password" : "text"}
      name={name}
      defaultValue={value}
      style={value === '' ? webStyles.custominputPlaceholder : successInput}
      onChange={onChange}
      placeholder={placeholder}
      autoComplete="off"
    />
      <IconButton
        data-test-id="eyeicon"
        aria-label="toggle password visibility"
        onClick={handleClickShowPassword}
        edge="end"
        style={webStyles.iconBtn}
      >
        {!enablePasswordField ? (
          <VisibilityOutlinedIcon />
        ) : (
          <VisibilityOffOutlinedIcon />
        )}
      </IconButton>
  </div>};

export const CommonButtons: React.FC<CommonButtonsProps> = ({error, handleCancel, values, handleChangePassword}) => {
  return <Box sx={webStyles.cmnBtnLayout}>
    <button data-test-id="btnCancel" type="button" style={webStyles.cancelsBtn} onClick={handleCancel}>
      Cancel
    </button>
    <button data-test-id="btnAddGroup" type="submit" style={error ? webStyles.passwordDisableBtn : webStyles.passwordDoneBtn} onClick={() => {if(!error) handleChangePassword(values.old_password, values.new_password)}} >
      Done
    </button>
  </Box>
 }

 export const ConfirmPasswordMessages: React.FC<ConfirmPasswordMessagesProps> = ({errors, values}) => {                      
    if(values.confirm_new_password?.length > 0 && !Boolean(errors['confirm_new_password']) && !Boolean(errors['new_password'])){
      return <div style={webStyles.successMessage}>
      <img src={Success}/>
      Passwords matched
    </div>
    }
    else {
      return <div style={{ height: '20px'}}>{" "}</div>
    }
 }

const getHeading = (isSamePass:boolean) => {
  return isSamePass?"Invalid New Password":""
}

const getErrorMessage = (isSamePass:boolean,errorMessage:string) => {
  return isSamePass?errorMessage:"Your old password has been entered incorrectly. Please enter it again."
}

 export default class ChangePassword extends CustomisableUserProfilesController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const password = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?\d)(?=.*?[#?!@$%^&*-]).{8,}$/;

    

    const AddPasswordValidationSchema = yup.object().shape({
      old_password: yup.string().trim().required("Old Password is required"),
      new_password: yup
        .string()
        .trim()
        .required("New Password is required")
        .matches(password, "Please enter a valid password")
        .notOneOf([yup.ref("old_password")],"New password must be different from the current password."),
      confirm_new_password: yup
        .string()
        .trim()
        .required("Confirm Password is required")
        .oneOf(
          [yup.ref("new_password")],
          "Password do not match"
        )
    });

  return (
    <>
    {this.state.dataLoading ? <Box p='50px 40px'><Box style={webStyles.loaderStyle}><CircularProgress style={{ color: '#2B3D7D'}}/></Box></Box> :
    <Formik
      initialValues={{
        old_password: "",
        new_password: "",
        confirm_new_password: "",
      }}
      onSubmit={() => {}}
      enableReinitialize
      validationSchema={AddPasswordValidationSchema}
    >
      {({ values, dirty, isValid, touched, errors, handleChange, setFieldTouched, setFieldValue }) => { 
        const confirmPasswordSuccess = values.confirm_new_password?.length > 0 && !Boolean(errors['confirm_new_password']) &&  !Boolean(errors['new_password']);
        const disableButton = !dirty || !isValid;  
        const checkField = (field: any) => {
          // @ts-ignore
          return touched[field] && Boolean(errors[field])
        }
        return (
          <Form>           
<Box sx={webStyles.inputWrapper}>
  <Grid container spacing={3}>
    <Grid item xs={12} sm={6}>
      <div>
        <PasswordInput
          data-test-id="oldpassword"
          label="Old Password"
          name="old_password"
          value={values.old_password}
          onChange={(e) => {e.persist();
            handleChange(e);
            setFieldTouched("old_password", true, false)}}
          enablePasswordField={this.state.showPassword}
          handleClickShowPassword={this.handleClickShowPassword}
          error={checkField('old_password')}
          placeholder={"Enter Old Password"}
        />
        <div style={{marginTop: "10px"}}>
          {checkField('old_password') ?
            <div style={webStyles.error}>
              <img src={Error}/>
              {errors['old_password']}
            </div>
            :
            <div style={{ height: '20px'}}>{" "}</div>
          }
        </div>
      </div>
      <div style={{ marginTop: '20px'}}>
        <PasswordInput
          data-test-id="confirmpassword"
          label="Confirm Password"
          name="confirm_new_password"
          value={values.confirm_new_password}
          onChange={(e) => {e.persist();
            handleChange(e);
            setFieldTouched("confirm_new_password", true, false)}}
          enablePasswordField={this.state.showConfirmPassword}
          handleClickShowPassword={this.handleClickShowConfirmPassword}
          error={checkField('confirm_new_password')}
          placeholder={"Re-enter New Password"}
          isSuccess={confirmPasswordSuccess}
        /> 
        <div style={{marginTop: "10px"}}>
          {checkField('confirm_new_password') ?
            <div style={webStyles.error}>
              <img src={Error}/>
              {errors['confirm_new_password']}
            </div>
            :
            <ConfirmPasswordMessages values={values} errors={errors} />
          }
        </div>
      </div>
    </Grid>
    <Grid item xs={12} sm={6}>
      <PasswordInput
        data-test-id="newpassword"
        label="New Password"
        name="new_password"
        value={values.new_password}
        onChange={(e) => {e.persist();
          handleChange(e);
          setFieldTouched("new_password", true, false)}}
        enablePasswordField={this.state.showNewPassword}
        handleClickShowPassword={this.handleClickShowNewPassword}
        error={checkField('new_password')}
        placeholder={"Create New Password"}
        isSuccess={values.new_password?.length > 0 && confirmPasswordSuccess}
      />
      {checkField('new_password') ?
        <div style={{display: 'flex', flexDirection: 'column', marginTop: "10px"}}>
          <div style={webStyles.error} >
            <img src={Error}/>
            {errors['new_password']}
          </div>
          {errors['new_password'] === 'Please enter a valid password' && 
          <div style={{marginLeft: '27px'}}>
            <div style={webStyles.error}>Your Password must include at least:</div>
            <div style={webStyles.error}>- 8 characters length</div>
            <div style={webStyles.error}>- One Upper case character (A-Z)</div>
            <div style={webStyles.error}>- One Lower case character (a-z)</div>
            <div style={webStyles.error}>- One Digit (0-9)</div>
            <div style={webStyles.error}>- One Symbol/Special character (!,#,$,etc)</div>
          </div>}
        </div>
        :
        <div style={{ height: '20px'}}>{" "}</div>
      }
    </Grid>
  </Grid>

  <Grid container  spacing={3} xs={12}>
    <Grid item xs={12}></Grid>
    <Grid item xs={12}> 
        <CommonButtons error={disableButton} handleCancel={() => {window.location.replace('/profile')}} values={values} handleChangePassword={this.handleChangePassword}/>


    </Grid>

  </Grid>
</Box>

            <Modal open={this.state.isVisibleModal} onClose={() => {this.hideModal(); setFieldValue("old_password", ""); setFieldValue("new_password", ""); setFieldValue("confirm_new_password", ""); this.handleClickLoadData();  setTimeout(() => {this.handleClickLoadData()}, 1000)}}>
              <Box sx={webStyles.modalsStyles}>
              <img style={webStyles.closeIcon} src={CloseIcon} onClick={() => {this.hideModal(); setFieldValue("old_password", ""); setFieldValue("new_password", ""); setFieldValue("confirm_new_password", ""); this.handleClickLoadData();  setTimeout(() => {this.handleClickLoadData()}, 1000)}}/>
              <img src={SuccessImage} style={{ margin: "30px 0px"}}/>
              <div style={webStyles.modalHeading}>Password Changed Successfully!</div>
              <div style={webStyles.modalSubHeading}>You have successfully updated your password</div>
              </Box>
            </Modal>
            <Modal open={this.state.isVisibleErrorModal} onClose={() => {this.hideErrorModal(); setFieldValue("old_password", ""); setFieldValue("new_password", ""); setFieldValue("confirm_new_password", ""); this.handleClickLoadData();  setTimeout(() => {this.handleClickLoadData()}, 1000)}}>
              <Box sx={webStyles.modalsStyles}>
              <img style={webStyles.closeIcon} src={CloseIcon} onClick={() => {this.hideErrorModal(); setFieldValue("old_password", ""); setFieldValue("new_password", ""); setFieldValue("confirm_new_password", ""); this.handleClickLoadData();  setTimeout(() => {this.handleClickLoadData()}, 1000)}}/>
              <img src={ErrorImage} style={{ margin: "13px 0px"}}/>
              <div style={webStyles.modalHeading}>{getHeading(this.state.isSamePass)}</div>
              <div style={webStyles.modalSubHeading}>{getErrorMessage(this.state.isSamePass,this.state.errorMessage)}</div>
              </Box>
            </Modal>
          </Form>
        );
      }}
    </Formik>}
    </>
  )}
}


