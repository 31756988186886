// Customizable Area Start
import React from "react";
import TaskListController from "./TaskListController";
import { withStyles } from "@material-ui/styles";
import { Box, Grid, Link, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, makeStyles } from "@material-ui/core";
import BasicPagination from "../../../components/src/BasicPagination.web";
import { assignmentIcon, back, clockIcon, viewQuizzes } from "./assets";
import Sidebar from "../../../components/src/Sidebar.web";
import AppHeaderWeb from "../../../components/src/AppHeader.web";
import SearchInput from "../../../components/src/SearchInput.web";
import Loader from "../../../components/src/Loader.web";
import { FilterIcon } from "../../dashboard/src/assets";
import { searchIcon } from "../../../components/src/assets";

const webStyle = {
  addButtonLayout: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: "20px",
    marginBottom: "20px",
  },
  searchBarLayout: {
    display: "flex",
    gap: "5%"
  },
  searchbarBox: {
    display: "flex",
    position: "relative" as "relative",
    gap: "4px",
  },
  searchbarIconbox: {
    position: "absolute" as "absolute",
    top: "13px",
    left: "10px",
  },
  searchFilterinput: {
    fontSize: "16px",
    border: "1px solid rgba(43,61,125,0.39)",
    height: "52px",
    width: "538px",
    background: "#fff",
    fontFamily: "Poppins",
    fontWeight: 400,
    borderRadius: "8px",
    paddingLeft: "40px",
    '@media (max-width: 600px)': {
      width: '100%'
    }
  },
}
class TaskPagesList extends TaskListController {
  render() {
    return (<>
      {
        this.props.tabValue !== 0 && <Box style={webStyle.addButtonLayout} sx={{ px: "35px" }}>
        <Box style={webStyle.searchBarLayout}>
        <Box style={webStyle.searchbarBox}>
                    <Box style={webStyle.searchbarIconbox}> 
                      <img src={searchIcon} alt="Search Icon" /></Box>
                    <input
                      style={webStyle.searchFilterinput}
                      data-test-id='inputSearch'
                      value={this.state.searchValue}
                      placeholder='Search by name'
                      onChange={(e) => {
                        this.handleSeachChange(e.target.value)
                      }}
                    />
                  </Box> 
          </Box>
      </Box>
      }
      {!this.props.activeState && this.props.tabValue === 1 && (
        <FeedbackSent getFeedbacksSent={this.getFeedbacksSent.bind(this)} taskLists={this.state.taskLists} loading={this.state.loading} handleGoToDetailsPage={this.handleGoToDetailsPage.bind(this)} page={this.props.taskListPageNo} pageLimit={this.props.taskListPageLimit} handlePageChange={this.handleFeedbackPageChange.bind(this)}/>
      )}
      {!this.props.activeState && this.props.tabValue === 2 && (
        <PendingReview getPendingReview={this.getPendingReviews.bind(this)} taskLists={this.state.taskLists} loading={this.state.loading} handleGoToDetailsPage={this.handleGoToDetailsPage.bind(this)} page={this.props.taskListPageNo} pageLimit={this.props.taskListPageLimit} handlePageChange={this.handleFeedbackPageChange.bind(this)}/>
      )}
      {this.props.activeState && this.props.tabValue === 2 && (
        <QuizPendingReview getPendingReview={this.getPendingReviews.bind(this)} taskLists={this.state.taskLists} loading={this.state.loading} handleGoToDetailsPage={this.handleGoToDetailsPage.bind(this)} page={this.props.taskListPageNo} pageLimit={this.props.taskListPageLimit} handlePageChange={this.handleFeedbackPageChange.bind(this)}/>
      )}
      {this.props.activeState && this.props.tabValue === 1 && (
        <QuizFeedbackSent getQuizFeedbacksSent={this.getQuizFeedbacksSent.bind(this)} taskLists={this.state.taskLists} loading={this.state.loading} handleGoToDetailsPage={this.handleGoToDetailsPage.bind(this)} page={this.props.taskListPageNo} pageLimit={this.props.taskListPageLimit} handlePageChange={this.handleFeedbackPageChange.bind(this)}/>
      )}
    </>)
  }
}
export default TaskPagesList;

const useStyles = makeStyles(() => ({
  action2: {
    borderRadius: "8px",
    border: "0.2px solid #2B3D7D",
    color: "#2B3D7D",
    padding: "5px",
    width: "fit-content"
  },
  table: {
    minWidth: 700,
  },
  assignmentName: {
    marginLeft: "10px"
  },
  assigName: {
    marginLeft: "10px",
    cursor: 'pointer',
  },
  assignmentContainer: {
    display: "flex",
    alignItems: "center"
  },
  addButtonLayout: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: "20px",
    marginBottom: "20px",
  },
  searchBarLayout: {
    display: "flex",
    gap: "5%"
  },
  noRecords: {
    fontFamily: "Poppins",
    textAlign: "center",
    paddingTop: "35px",
  },
}));
const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#FDF4DC",
    color: "#202020",
    fontFamily: "Poppins",
    fontWeight: 500,
    fontSize: "16px",
  },
  body: {
    fontSize: "14px",
    color: "#3F526D",
    fontFamily: "Poppins",
    fontWeight: 400,
  },
}))(TableCell);

const StyledTableRow = withStyles(() => ({}))(TableRow);
const tableHead = () => {
  return (
    <TableRow>
      <StyledTableCell align="left">Assignments</StyledTableCell>
      <StyledTableCell align="left">Class</StyledTableCell>
      <StyledTableCell align="left">Assignments Due Date</StyledTableCell>
      <StyledTableCell align="left">Submitted</StyledTableCell>
    </TableRow>
  );
};

export const FeedbackSent = (props: any) => {
  const classes = useStyles();
  const { getFeedbacksSent, 
    taskLists, 
    handleGoToDetailsPage,
    page,
    pageLimit,
    handlePageChange,
    loading
  } = props

  React.useEffect(() => {
    getFeedbacksSent()
  }, [])
  const tableBody = () => {
    return taskLists?.map((data: any, index: number) => (
      <> <StyledTableRow key={data.id}>
        <StyledTableCell align="left" >
          <Box data-test-id='goToDetailsPage' className={classes.assignmentContainer} onClick={()=> handleGoToDetailsPage(data)}>
            <img src={assignmentIcon} />
            <Link href={`feedbackDetails/${data.id}`}>
              <Typography className={classes.assigName}>{data?.attributes?.assignment?.data?.attributes?.assignment_name}</Typography>
            </Link>
          </Box>
        </StyledTableCell>
        <StyledTableCell align="left">{data?.attributes?.assignment?.data?.attributes?.class_name}</StyledTableCell>
        <StyledTableCell align="left" >
          <Box style={{ display: "flex", flexDirection: "row" }}>
            <img src={clockIcon} />
            <Typography style={{ marginLeft: "10px", color: data && data.attributes && data.attributes.days_left <=5 ? '#E02020' : '#2c2c2e' }}  >{data && data.attributes && data.attributes.days_left} days left</Typography>
          </Box>
        </StyledTableCell>
        <StyledTableCell align="left">{data && data.attributes && data.attributes.assignment && data.attributes.assignment.data && data.attributes.assignment.data.attributes && data.attributes.assignment.data.attributes.assignment_feedback_count}</StyledTableCell>
      </StyledTableRow>
      </>
    ));
  };
  return (
    <>
      <Box sx={{ px: "35px" }}>
      {loading ? (
          <Loader loading />
        ) : 
        (
          <Box>{taskLists?.length === 0 ? <Typography className={classes.noRecords}>No records found</Typography>
          : <>
            <TableContainer component={Paper}>
              <Table className={classes.table} aria-label="customized table">
                <TableHead>
                  {tableHead()}
                </TableHead>
                <TableBody>{tableBody()}</TableBody>
              </Table>
            </TableContainer>
            <Box sx={{ px: "35px", py: "50px" }}>
              <BasicPagination
                handlePageChange={handlePageChange}
                pageLimit={pageLimit}
                pageNo={page}
              />
            </Box>
          </>
          }
          </Box>
        )
      }
      </Box>
    </>
  );
};
export const PendingReview = (props: any) => {
  const { getPendingReview, taskLists,loading, handleGoToDetailsPage,
    page,
    pageLimit,
    handlePageChange,
   } = props
  const classes = useStyles();
  React.useEffect(() => {
    getPendingReview("assignment")
  }, [])
  const tableHead1 = () => {
    return (
      <TableRow>
        <StyledTableCell align="left">Assignments</StyledTableCell>
        <StyledTableCell align="left">Class</StyledTableCell>
        <StyledTableCell align="left">Assignments Due Date</StyledTableCell>
        <StyledTableCell align="left">Action</StyledTableCell>
      </TableRow>
    );
  };

  const tableBody = () => {
    return taskLists?.map((data: any, index: number) => (
      <> <StyledTableRow key={data.id}>
        <StyledTableCell align="left" >
          <Box className={classes.assignmentContainer}>
            <img src={assignmentIcon} />
            <Typography className={classes.assignmentName}>{data.attributes?.assignment?.data?.attributes?.assignment_name}</Typography>
          </Box>
        </StyledTableCell>
        <StyledTableCell align="left">{data.attributes?.assignment?.data?.attributes?.class_name}</StyledTableCell>
        <StyledTableCell align="left" >
          <Box style={{ display: "flex", flexDirection: "row" }}>
            <img src={clockIcon} />
            <Typography style={{ marginLeft: "10px", color: data && data.attributes && data.attributes.days_left <=5 ? '#E02020' : '#2c2c2e' }}>{data && data.attributes && data.attributes.days_left} Days left</Typography>
          </Box>
        </StyledTableCell>
        <StyledTableCell data-test-id='handleGoToDetailsPage' align="left"  onClick={()=> handleGoToDetailsPage(data)}>
          <Link href={`pendingDetails/${data.id}`}>
            <Typography className={classes.action2}>Open Submissions</Typography></Link>
        </StyledTableCell>
        </StyledTableRow>
      </>
    ));
  };

  return (
    <>
      <Box 
        sx={{ px: "35px" }}
        >
        {loading ? (
          <Loader loading />
        ) : ( <Box>
              {
                taskLists && taskLists.length === 0 ? (
                  <Typography className={classes.noRecords}> No records found </Typography>
                ) : (
              <>
        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="customized table">
            <TableHead> {tableHead1()} </TableHead>
            <TableBody>{tableBody()}</TableBody>
          </Table>
        </TableContainer>
        <Box sx={{ px: "35px", py: "50px" }}>
          <BasicPagination
            handlePageChange={handlePageChange}
            pageLimit={pageLimit}
            pageNo={page}
          />
        </Box></>)}
        </Box>)}
      </Box>
    </>
  );
};

export const QuizPendingReview = (props: any) => {
  const { getPendingReview, taskLists, loading, handleGoToDetailsPage,
    page,
    pageLimit,
    handlePageChange,
   } = props
  const classes = useStyles();
  React.useEffect(() => {
    getPendingReview("quiz")
  }, [])
  const tableHead1 = () => {
    return (
      <TableRow>
        <StyledTableCell align="left">Quizzes</StyledTableCell>
        <StyledTableCell align="left">Class</StyledTableCell>
        <StyledTableCell align="left">Quizzes Due Date</StyledTableCell>
        <StyledTableCell align="left">Action</StyledTableCell>
      </TableRow>
    );
  };

  const tableBody = () => {
    return taskLists && taskLists.map((data: any, index: number) => {
      const quizData = data && data.attributes && data.attributes.quiz && data.attributes.quiz.data && data.attributes.quiz.data.attributes;
      const quiz_name = quizData && quizData.quiz_name
      const class_name = quizData && quizData.class_name
      const due_date = data && data.attributes && data.attributes.days_left
      const quiz_id = data && data.attributes && data.attributes.quiz && data.attributes.quiz.data && data.attributes.quiz.data.id
      return (
      <> <StyledTableRow key={quiz_id}>
        <StyledTableCell align="left" >
          <Box className={classes.assignmentContainer}>
            <img src={viewQuizzes} />
            <Typography className={classes.assignmentName}>{quiz_name}</Typography>
          </Box>
        </StyledTableCell>
        <StyledTableCell align="left">{class_name}</StyledTableCell>
        <StyledTableCell align="left" >
          <Box style={{ display: "flex", flexDirection: "row" }}>
            <img src={clockIcon} />
            <Typography style={{ marginLeft: "10px", color: due_date <=5 ? '#E02020' : '#2c2c2e' }}>{due_date} Days left</Typography>
          </Box>

        </StyledTableCell>
        <StyledTableCell data-test-id='quizpendinggotodetails' align="left" onClick={()=> handleGoToDetailsPage(data)}>
          <Link href={`pendingsReviewQuiz/${data.id}`}>
              <Typography className={classes.action2}>Open Submissions</Typography>
          </Link>
        </StyledTableCell>
      </StyledTableRow>
      </>
    )});
  };
  return (
    <>
      <Box sx={{ px: "35px" }}>
        {loading ? (
          <Loader loading />
        ) : (
          <Box>
            {taskLists?.length === 0 ? (
              <Typography className={classes.noRecords}>
                No records found
              </Typography>
            ) : (
              <>
                <TableContainer component={Paper}>
                  <Table className={classes.table} aria-label="customized table">
                    <TableHead>
                      {tableHead1()}
                    </TableHead>
                    <TableBody>{tableBody()}</TableBody>
                  </Table>
                </TableContainer>
                <Box sx={{ px: "35px", py: "50px" }}>
                  <BasicPagination
                    handlePageChange={handlePageChange}
                    pageLimit={pageLimit}
                    pageNo={page}
                  />
                </Box></>)}</Box>)}
      </Box>
    </>
  );
};
export const QuizFeedbackSent = (props: any) => {
  const classes = useStyles();
  const { taskLists, getQuizFeedbacksSent, handleGoToDetailsPage,
    page,
    pageLimit,
    handlePageChange,
    loading
   } = props;

  React.useEffect(() => {
    getQuizFeedbacksSent();
  }, [])

  const tableHead1 = () => {
    return (
      <TableRow>
        <StyledTableCell align="left">Quizzes</StyledTableCell>
        <StyledTableCell align="left">Class</StyledTableCell>
        <StyledTableCell align="left">Quizzes Due Date</StyledTableCell>
        <StyledTableCell align="left">Submitted</StyledTableCell>
      </TableRow>
    );
  };
  
  const tableBody = () => {
    return taskLists && taskLists.map((data: any, index: number) => {
      const quizData = data && data.attributes && data.attributes.quiz && data.attributes.quiz.data && data.attributes.quiz.data.attributes;
      const due_date = data && data.attributes && data.attributes.days_left
      const class_name = quizData && quizData.class_name
      const completed_count = data && data.attributes && data.attributes.quiz && data.attributes.quiz.data && data.attributes.quiz.data.attributes && data.attributes.quiz.data.attributes.quiz_feedback_count
      const quiz_name = quizData && quizData.quiz_name

      return (
      <> <StyledTableRow key={data.id}>
        <StyledTableCell align="left" >
          <Box className={classes.assignmentContainer} data-test-id='goToDetailsPage' onClick={()=> handleGoToDetailsPage(data)}>
            <img src={viewQuizzes} />
            <Link href={`feedbackSentQuiz/${data.id}`}>
            <Typography className={classes.assignmentName}>{quiz_name}</Typography>
            </Link>
          </Box>
        </StyledTableCell>
        <StyledTableCell align="left">
          {class_name}
          </StyledTableCell>
        <StyledTableCell align="left" >
          <Box style={{ display: "flex", flexDirection: "row" }}>
            <img src={clockIcon} />
            <Typography style={{ marginLeft: "10px", color: due_date <=5 ? '#E02020' : '#2c2c2e' }}>
              {due_date} Days left
            </Typography>
          </Box>

        </StyledTableCell>
        <StyledTableCell align="left">
          {completed_count}
          </StyledTableCell>
      </StyledTableRow>
      </>
    )});
  };
  return (
    <>
      <Box sx={{ px: "35px" }}>
      {loading ? (
          <Loader loading />
        ) : 
        (
        <Box>{taskLists && taskLists.length === 0 ? (
                <Typography className={classes.noRecords}> No records found
                </Typography>
              ) : (
                <>
                  <TableContainer component={Paper}>
                    <Table className={classes.table} aria-label="customized table">
                      <TableHead> {tableHead1()} 
                      </TableHead>
                      <TableBody>{tableBody()}</TableBody>
                    </Table>
                  </TableContainer>
                  <Box sx={{ px: "35px", py: "50px" }}>
                    <BasicPagination
                      handlePageChange={handlePageChange}
                      pageNo={page} 
                      pageLimit={pageLimit}
                    />
                  </Box>
                </>
              )}
              </Box>
        
        )}
      </Box>
    </>
  );
};
// Customizable Area End