import React, { useEffect } from "react";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import { Box,Typography,Table,TableBody,TableCell,TableContainer,TableHead,TableRow, Paper} from "@material-ui/core";
import TeacherCustomContextMenu from "./TeacherCustomContextMenu.web";
import moment from "moment";
import { notesIcon, lessonIcon,folderIcon,quizIcon,assignmentIcon } from "../../../assets"
import { CLASS_OPTIONS } from "../../../../../../components/src/types";
import CustomSuccessPopup from "../../../../../../components/src/CustomSuccessPopup.web";
import CustomEditModal from "../../../../../../components/src/CustomEditModal.web";

const StyledTableCell = withStyles(() => ({
  head: {
    backgroundColor: "#FDF4DC",
    color: "#202020",
    fontFamily: "Poppins",
    fontWeight: 500,
    fontSize: "16px",
  },
  body: {
    fontSize: "14px",
    color: "#3F526D",
    fontFamily: "Poppins",
    fontWeight: 400,
  },
}))(TableCell);

const StyledTableRow = withStyles(() => ({}))(TableRow);
const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
  noRecordsText: {
    fontFamily: "Poppins",
    textAlign: "center",
    paddingTop: "35px",
  },
  practiceImg: {
    width: "28px",
    height: "28px",
    marginRight: "30px",
    cursor:"pointer"
  },
  practiceColumnBox: {
    display: "flex",
    alignItems: "center",
  },
  noteIcon: {
    width: "28px",
    height: "28px",
    marginLeft: "30px",
    cursor:"pointer",
  },
  title:{
    fontSize: "14px",
    color: "#3F526D",
    fontFamily: "Poppins",
    fontWeight: 400,
    cursor:"pointer"
  }
});

export default function PracticeCustomTable(props: any) {
  const { 
    practiceData,
    handleShowNotesIcon,
    notesList,
    openFolderList,
    handleOpenFolderList,
    handleCloseFolderList,
    folderAnchorEl,
    folderList,
    handleOpenMoveConfirmationPopUp,
    handleCloseMoveConfirmationPopUp,
    openMovePopup,
    moveLessonToFolder,
    handleSetFolderId,
    folderId,
    openMoveSuccessPopup,
    handleCloseSuccessPopup,
    handleOpenSuccessPopup,
    handleFolderNameSearchInput,
    folderSearchInput,
    loading,
    deleteLessonFolder,
    showLessonsForSelectedFolder,
    handleOpenDeleteModal,
    handleCloseDeleteModal,
    openDeleteConfirmationModal,
    selectedLessons,
    role,
    generateLink,
    shareLink,
    deliverLessonApi,
    downloadLesson,
    editPractice,
    openAddFolderModal,
    handleOpenAddFolderModal,
    handleCloseAddFolderModal,
    handleChangeFolderInput,
    folderName,
    createLessonFolder,
    openSuccessBar,
    handleCloseDeleteSuccess,
    successBarData,
    handleCloseNoMobileModal,
    handleOpenNoMobileModal,
    openNoMobileModal,
    currentLocation,
    editFuncMyLibraryLesson,
    viewMyLibraryLesson,
    viewMyLibraryAssignment,
    showLessons,
    handleLessonNameChange,
    state,
    handleEditClassChange,
    handleEditStartDateChange,
    handleEditDueDateChange,
    handleLessonObjectiveChange,
    handleCloseEditModal,
    handleOpenEditModal,
    viewMyLibraryQuiz,
    editFuncMyLibraryQuiz,
    editFuncMyLibraryAssignment,
    handleEditUploadIcon,
    selectedType
  } = props;
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [openContextMenuList, setOpenContextMenuList] = React.useState(false);
  const [contextMenuListData, setContextMenuListData] = React.useState<any>({});
  const [openAddNotesModal, setOpenAddNotesModal] = React.useState<any>({ show: false, type: "" });
  const [notesData, setNotesData] = React.useState<any>([]);
  const [openShareLinkModal, setOpenShareLinkModal] = React.useState(false);

  const handleOpenContextMenuList = (
    event: any,
    id: string,
    name: string,
    type: string,
    itemType:string,
    shared:boolean,
    totalStage:number
  ) => {
    event.preventDefault();
    setContextMenuListData({
      id,
      type,
      name,
      itemType,
      shared,
      totalStage
    });
    setAnchorEl(event.currentTarget);
    setOpenContextMenuList(true);
  };
  const handleCloseContextMenuList = (event: any) => {
    event.preventDefault();
    setAnchorEl(null);
    setOpenContextMenuList(false);
  }

  const handleCloseAddNotesModal = () => {
    setOpenAddNotesModal({ show: false, type: "" });
  };
  const handleOpenAddNotesModal = (value: string, id:any, name:string, itemType:string ) => {
    setOpenAddNotesModal({ show: true, type: value,id:id, name:name, itemType:itemType });
  };
const showIcon=()=>{
  const list = notesList.map((obj:any)=>obj.lessonId)
  setNotesData(list)
}
const handleCloseShareLinkModal = () => {
  setOpenShareLinkModal(false);
};
const handleOpenShareLinkModal = () => {
  setOpenShareLinkModal(true);
};
const renderIcon=()=>{
  if(practiceData.type===CLASS_OPTIONS.LESSONS){
    return lessonIcon
  } else if(practiceData.type===CLASS_OPTIONS.QUIZZES){
    return quizIcon
  } else{
    return assignmentIcon
  }
}

useEffect(()=>{
  showIcon()
},[notesList])
  return (
    <>
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell align="left">{practiceData.type}</StyledTableCell>
              <StyledTableCell align="center">Stages</StyledTableCell>
              <StyledTableCell align="center">Created On</StyledTableCell>
              <StyledTableCell align="center">Last Updated On</StyledTableCell>
              <StyledTableCell align="center">Action</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {practiceData.data.map((data: any) => (
              <StyledTableRow key={data.id}>
                <StyledTableCell component="th" scope="row">
                  <Box className={classes.practiceColumnBox}>
                    <img
                      data-test-id="open-folder"
                      className={classes.practiceImg}
                      src={data?.itemType==="folder"?folderIcon:renderIcon()}
                      onClick={()=>{data?.itemType==="folder" && showLessonsForSelectedFolder(data.name,data.id)}}
                    />{" "}
                    <Typography className={classes.title}
                    onClick={()=>{data?.itemType==="folder" && showLessonsForSelectedFolder(data.name,data.id)}}
                    >{data.name}</Typography>
                    {
                    notesList && notesList.find((obj:any)=>obj.lessonId===Number(data.id) && obj.type === data.itemType) && (
                      <img 
                      onClick={()=>handleOpenAddNotesModal("Edit",data.id, data.name, data.itemType)}
                        className={classes.noteIcon}
                        src={notesIcon}
                      />
                    )}
                  </Box>
                </StyledTableCell>
                <StyledTableCell align="center">{data.totalStage}</StyledTableCell>
                <StyledTableCell align="center" className="practiceData">{data.createdAt}</StyledTableCell>
                <StyledTableCell align="center" className="practiceData">{data.updatedAt}</StyledTableCell>
                <StyledTableCell align="center">
                  <MoreVertIcon
                    data-test-id="action-icon"
                    style={{cursor:"pointer"}}
                    onClick={(e) =>
                      handleOpenContextMenuList(
                        e,
                        data.id,
                        data.name,
                        practiceData.type,
                        data.itemType,
                        data?.shared,
                        data.totalStage,
                      )
                    }
                  />
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <CustomEditModal open={state?.openEditModal} handleClose={handleCloseEditModal} selectedOption={currentLocation} classList={state?.classList} editLessonApiCall={editFuncMyLibraryLesson} showLessons={showLessons} handleLessonNameChange={handleLessonNameChange} 
        state={state} handleEditClassChange={handleEditClassChange} handleEditStartDateChange={handleEditStartDateChange} handleEditDueDateChange={handleEditDueDateChange} 
        handleLessonObjectiveChange={handleLessonObjectiveChange}
        editFuncMyLibraryAssignment={editFuncMyLibraryAssignment}
        editFuncMyLibraryQuiz={editFuncMyLibraryQuiz}
        handleEditUploadIcon={handleEditUploadIcon}
      />
      <TeacherCustomContextMenu
        data-test-id="context-menu"
        anchorEl={anchorEl}
        openContextMenu={openContextMenuList}
        handleCloseContextMenu={handleCloseContextMenuList}
        contextMenuData={contextMenuListData}
        handleCloseAddNotesModal={handleCloseAddNotesModal}
        handleOpenAddNotesModal={handleOpenAddNotesModal}
        openAddNotesModal={openAddNotesModal}
        handleShowNotesIcon={handleShowNotesIcon}
        noteData={notesData}
        openFolderList={openFolderList}
        handleOpenFolderList={handleOpenFolderList}
        handleCloseFolderList={handleCloseFolderList}
        folderAnchorEl={folderAnchorEl}
        folderList={folderList}
        handleOpenMoveConfirmationPopUp={handleOpenMoveConfirmationPopUp}
        handleCloseMoveConfirmationPopUp={handleCloseMoveConfirmationPopUp}
        openMovePopup={openMovePopup}
        moveLessonToFolder={moveLessonToFolder}
        handleSetFolderId={handleSetFolderId}
        folderId={folderId}
        openMoveSuccessPopup={openMoveSuccessPopup}
        handleCloseSuccessPopup={handleCloseSuccessPopup}
        handleOpenSuccessPopup={handleOpenSuccessPopup}
        folderSearchInput={folderSearchInput}
        handleFolderNameSearchInput={handleFolderNameSearchInput}
        loading={loading}
        handleCloseDeleteModal={handleCloseDeleteModal}
        handleOpenDeleteModal={handleOpenDeleteModal}
        openDeleteConfirmationModal={openDeleteConfirmationModal}
        handleOpenNoMobileModal={handleOpenNoMobileModal}
        handleCloseNoMobileModal={handleCloseNoMobileModal}
        openNoMobileModal={openNoMobileModal}
        deleteLessonFolder={deleteLessonFolder}
        selectedLessons={selectedLessons}
        handleOpenShareLinkModal={handleOpenShareLinkModal}
        handleCloseShareLinkModal={handleCloseShareLinkModal}
        openShareLinkModal={openShareLinkModal}
        role={role}
        generateLink={generateLink}
        shareLink={shareLink}
        deliverLessonApi={deliverLessonApi}
        downloadLesson={downloadLesson}
        editPractice={editPractice}
        openAddFolderModal={openAddFolderModal}
        handleCloseAddFolderModal={handleCloseAddFolderModal}
        handleOpenAddFolderModal={handleOpenAddFolderModal}
        handleChangeFolderInput={handleChangeFolderInput}
        folderName={folderName}
        updateLessonFolder={createLessonFolder}
        currentLocation={currentLocation}
        handleOpenEditModal={handleOpenEditModal}
        viewMyLibraryLesson={viewMyLibraryLesson}
        viewMyLibraryAssignment={viewMyLibraryAssignment}
        viewMyLibraryQuiz={viewMyLibraryQuiz}
        selectedType={selectedType}
      />
      <CustomSuccessPopup 
      open={openSuccessBar}
      onClose={handleCloseDeleteSuccess}
      successBarData={successBarData}
      />
    </>
  );
}
