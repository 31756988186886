import React, { useState } from "react";
import { Box, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography,Popover, useMediaQuery } from "@material-ui/core";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import CustomBreadcrumb from "../../../../../components/src/CustomBreadcrumb.web";
import BasicPagination from "../../../../../components/src/BasicPagination.web";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import SearchInput from "../../../../../components/src/SearchInput.web";
import Loader from "../../../../../components/src/Loader.web";
import {
  previewOption,
  downloadOption,
  previewOptionActive,
  downloadOptionActive,
} from "../../assets";
import { dateTimeFormat } from "../../../../../components/src/CustomDateTimeFormat.web";

const useStyles = makeStyles({
  noRecords: {
    fontFamily: "Poppins",
    textAlign: "center",
    paddingTop: "35px",
    width: "100%"
  },
  image: {
    marginRight: "10px",
  },
  normalBtn: {
    padding: "10px",
    textTransform: "none",
    border: "1px solid #FFF",
    color: "#000",
    backgroundColor:"#fff",
    fontFamily: "Poppins",
    fontSize: "16px",
    fontWeight: 500,
    justifyContent: "start",
    display:"flex",
    whiteSpace: "nowrap",
    "&:hover": {
      backgroundImage:
        "linear-gradient(to right bottom, rgba(0,10,77,1) 0%, rgba(21,8,112,1) 36%, rgba(41,142,139,1) 100%)",
      color: "#FFF",
    },
    cursor:"pointer"
  },
  dropdownmore: {
    display: "flex",
    flexDirection:"column",
    borderRadius: "6px",
    width: "max-content",
    minWidth:"135px",
    border:"1px solid #EBEBEB",
    boxShadow:"0 0 14px 0 rgba(49, 121, 149, 0.58)",
    padding:"7px 0",
    position:"relative",
    backgroundColor:"#FFF",
    "&::before":{
      top: "-10px",
      right: "12px",
      width: "0",
      height: "0",
      content: "' '",
      position: "absolute",
      borderBottom: "10px solid #fff",
    }

  },
  menu:{
    cursor:"pointer"
  },
  table: {
    minWidth: 700,
  },
  practiceColumnBox: {
    display: "flex",
    alignItems: "center",
  },
  practiceImage: {
    width: "28px",
    height: "28px",
    marginRight: "30px",
  },
  practiceNameColumnBox: {
    display: "flex",
    alignItems: "center",
  },
  menuPopOver:{
    "& .MuiPopover-paper":{
      boxShadow:"none !important",
      backgroundColor:"transparent",
    },
  }
});
const StyledTableCell = withStyles(() => ({
  head: {
    backgroundColor: "#FDF4DC",
    color: "#202020",
    fontFamily: "Poppins",
    fontWeight: 500,
    fontSize: "16px",
  },
  body: {
    fontSize: "14px",
    color: "#3F526D",
    fontFamily: "Poppins",
    fontWeight: 400,
  },
}))(TableCell);
const StyledTableRow = withStyles(() => ({}))(TableRow);
const SharedSelectedPractice = (props: any) => {
  const classes = useStyles();
  const isSmallScreen = useMediaQuery('(max-width:600px)');

  const {
    locations,
    currentLocation,
    redirect,
    sharedData,
    tableSearchInput,
    handleSharedSearchInputTableForTeacher,
    pageLimitPractice,
    handleSharedtablePageChange,
    pageNoPractice,
    loading,
    downloadLesson,
    selectedLessons
  } = props;
  const [anchorEl,setAnchorEl]= useState<Element | null>(null);
  const viewRef = React.useRef<any>(null);
  const downloadRef = React.useRef<any>(null)
  const [contextData,setContextData] = React.useState<any>({})
const handleOpenMenu=(e:any,id:string,type:string)=>{
  setAnchorEl(e.target)
  setContextData({
    id,
    type,
  });
}
const handleClose=()=>{
setAnchorEl(null)
}
  return (
    <>
      <Box sx={{ pl: "35px", mt: "30px" }}>
        <CustomBreadcrumb
          locations={locations}
          destination={currentLocation}
          redirect={redirect}
        />
      </Box>
      <Grid container={isSmallScreen ? false : true}>
        <Grid item md={12}>
          <Box sx={{ px: "35px", mb: "20px",mt:"20px" }}>
            <SearchInput
              searchInput={tableSearchInput}
              handleSearchInput={handleSharedSearchInputTableForTeacher}
              placeholder={"Search by name"}
            />
          </Box>
        </Grid>
        {loading ? (
          <Loader loading />
        ) : (<>
        {!sharedData.data.length ? (
          <Typography className={classes.noRecords}>
            No records found
          </Typography>
        ) : (<>
          <Grid item md={12}>
            <Box sx={{ px: "35px", mb: "20px", mt: "10px" }}>
              <TableContainer component={Paper}>
                <Table className={classes.table} aria-label="customized table">
                  <>
                    <TableHead>
                      <TableRow>
                        <StyledTableCell align="left">{sharedData.type}</StyledTableCell>
                        <StyledTableCell align="center">Created/Shared On</StyledTableCell>
                        <StyledTableCell align="center">Action</StyledTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {sharedData.data.map((data: any) => (
                        <StyledTableRow key={data.id}>
                          <StyledTableCell component="th" scope="row">
                            <Box className={classes.practiceNameColumnBox}>
                              <img
                                className={classes.practiceImage}
                                src={sharedData.iconType}
                              />{" "}
                              {data.name}
                            </Box>
                          </StyledTableCell>
                          <StyledTableCell align="center">{data.createdOn}</StyledTableCell>
                          <StyledTableCell align="center">
                            <MoreVertIcon
                            className={classes.menu}
                              data-test-id="action-icon"
                              onClick={(e) =>
                                handleOpenMenu(
                                  e,
                                  data.id,
                                  data.type
                                )
                              }
                            />
                          </StyledTableCell>
                        </StyledTableRow>
                      ))}
                    </TableBody>
                  </>
                </Table>
              </TableContainer>
            </Box>
            
          </Grid>
          <Grid item md={12}>
            <Box sx={{ px: "35px", my: "30px" }}>
              <BasicPagination
                handlePageChange={handleSharedtablePageChange}
                pageLimit={pageLimitPractice}
                pageNo={pageNoPractice}
              />
            </Box>
          </Grid>
        </>)}</>)}
      </Grid>
      <Popover
          anchorEl={anchorEl}
          open={anchorEl!=null}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",  
          }}
          transformOrigin={{          
            vertical: "top",     
            horizontal: "center", 
          }}
          className={classes.menuPopOver}
        >
            <div className={classes.dropdownmore}>
              <button
                data-test-id="view-option"
                onMouseEnter={(e) =>
                  (viewRef.current.src = previewOptionActive)
                }
                onMouseLeave={(e) => (viewRef.current.src = previewOption)}
                className={classes.normalBtn}
                onClick={()=>selectedLessons(contextData.id,false,contextData.type)}
              >
                 <img
            ref={viewRef}
            src={previewOption}
            className={classes.image}
          />
                View
              </button>
             
              <button
                data-test-id="download-option"
                onMouseEnter={(e) =>
                  (downloadRef.current.src = downloadOptionActive)
                }
                onMouseLeave={(e) => (downloadRef.current.src = downloadOption)}
                className={classes.normalBtn}
                onClick={()=>downloadLesson(contextData.type,contextData.id)}
              >
                 <img
            ref={downloadRef}
            src={downloadOption}
            className={classes.image}
          />
                Download
              </button>
            </div>
        </Popover>
    </>
  );
};

export default SharedSelectedPractice;
