import React from "react";
import {
  Box,
  Typography,
  FormControl,
  TextField,
  Grid,
  Button,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import ClearIcon from "@material-ui/icons/Clear";
import GenericButton from "../../../../../components/src/GenericButton.web";
import { getModalStyle } from "./utils";
import { classDefault2, fileDelete, fileTypePng } from "../../assets";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import moment from "moment";
import DateFnsUtils from "@date-io/date-fns";
const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    width: "465px",
    backgroundColor: theme.palette.background.paper,
    borderRadius: "10px",
    boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
    display: "flex",
    padding: "20px 30px",
    [theme.breakpoints.down('sm')]: {
      width: "80%",
      padding: "15px 20px",
    },
  },
  popupContent: {
    display: "flex",
    flexDirection: "column",
  },
  title: {
    fontFamily: "Poppins",
    fontSize: "22px",
    color: "#2C2C2E",
    fontWeight: 600,
    [theme.breakpoints.down('sm')]: {
      fontSize: "18px",
    },
  },
  image: {
    marginBottom: "20px",
  },
  container: {
    position: "relative",
    width: "100%",
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "25px",
  },
  selectBtn: {
    height: "20px",
    padding: "25.5px 14px",
    textTransform: "capitalize",
    fontFamily: "Poppins",
    fontSize: "14px",
    fontWeight: 400,
    color: "#2C2C2E",
    [theme.breakpoints.down('sm')]: {
      fontSize: "12px",
    },
  },
  inputValue: {
    fontFamily: "Poppins",
    fontSize: "16px",
    fontWeight: 400,
    color: "#282829",
    [theme.breakpoints.down('sm')]: {
      fontSize: "14px",
    },
  },
  buttonLayout: {
    display: "flex",
    justifyContent: "center",
    flexWrap: "wrap",
    gap: "10px",
    [theme.breakpoints.down('sm')]: {
      flexWrap: "nowrap",
    },
  },
  inputLabel: {
    padding: "10px 0",
    fontFamily: "Poppins",
    fontSize: "14px",
    fontWeight: 400,
    color: "#2C2C2E",
    [theme.breakpoints.down('sm')]: {
      fontSize: "12px",
    },
  },
  inputDivider: {
    marginBottom: "24px",
  },
  selectedImage: {
    borderRadius: "50%",
    border: "1px solid #D8D8D8",
    width: "120px",
    height: "120px",
    [theme.breakpoints.down('sm')]: {
      width: "100px",
      height: "100px",
    },
  },
  imageLayout: {
    display: "flex",
    justifyContent: "center",
  },
  error: {
    fontFamily: "Poppins",
    fontSize: "12px",
    color: "#FE575A",
    fontWeight: 400,
  },
  errorLayout: {
    display: "flex",
    marginTop: "10px",
  },
  selectLayout: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
  },
  selectLayoutLeft: {
    display: "flex",
    alignItems: "center",
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    marginRight: '10px',
  },
  dateFont: {
    "& .MuiInputBase-input": {
      color: "currentColor",
      fontFamily: "Poppins",
      fontSize: "14px",
      fontWeight: 400,
      [theme.breakpoints.down('sm')]: {
        fontSize: "12px",
      },
    },
  },
  dateFontPlaceholder: {
    "& .MuiInputBase-input": {
      color: "#C0C0C0",
      fontFamily: "Poppins",
      fontSize: "14px",
      fontWeight: 400,
      [theme.breakpoints.down('sm')]: {
        fontSize: "12px",
      },
    },
  },
}));


const CreateClassModal = (props: any) => {
  const { handleCloseModal, createClass } = props;
  const [modalStyle] = React.useState(getModalStyle);
  const classes = useStyles();
  const [errors, setErrors] = React.useState<any>({});
  const [formData, setFormData] = React.useState<any>({
    className: "",
    start_date: "",
    end_date: ""
  });
  const [image, setImage] = React.useState<any>(null);
  const fileInputRef = React.useRef<any>(null);
  const checkValidations = (name: string, value: string) => {
    let errorPresent:boolean = false;
  
    const validationRules: any = {
      className: /^.+$/,
      start_date: /^.+$/,
      end_date: /^.+$/,
    };
    if (!value.match(validationRules[name])) {
      const dateField = name == 'start_date'?"Start Date":"End Date"
      setErrors((prevErrors: any) => ({
        ...prevErrors,
        [name]: name=="className"?"Invalid input":`${dateField} is required`,
      }));
      errorPresent = true;
    } else {
      setErrors((prevErrors: any) => {
        const newErrors = { ...prevErrors };
        delete newErrors[name];
        return newErrors;
      });
      errorPresent = false;
    }
    setFormData({
      ...formData,
      [name]: value,
    });
    return errorPresent;
  };
  const handleChange = (e: any) => {
    const { name, value } = e.target;
    checkValidations(name, value);
  };
  const handleSubmit = () => {
    let errors = false;
    Object.keys(formData).forEach((name) => {
      if (checkValidations(name, formData[name])) {
        errors = true;
      }
    });
    const dateError = IsDateRequirementsValid();
    
    if (errors || dateError) return;
    formData.attachment = image;
    createClass(formData);
    handleCloseModal();
  };
  const handleSelectImage = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };
  const handleImageUpload = (event: any) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      setImage(selectedFile);
    }
  };
  const deleteImage = () => {
    setImage(null);
  };
  const initDate = (dateType: string) => {
    return formData[dateType]
      ? moment(formData[dateType], "DD/MM/YYYY", true).toDate()
      : undefined;
  };
  const handleDateChange = (date: any, dateType: string) => {
    setErrors((prevErrors: any) => {
      const newErrors = { ...prevErrors };
      delete newErrors["start_date"];
      delete newErrors["end_date"];
      return newErrors;
    });
    const formattedDate: string = moment(date).format("DD/MM/YYYY");
    handleChange({ target: { name: dateType, value: formattedDate } });
  };
  const IsDateRequirementsValid = () => {
    let errorPresent = false
    if (formData.start_date && formData.end_date) {
      const dateParts = formData.start_date.split("/");
      const eddateParts = formData.end_date.split("/");
      const startDate = new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0]); 
      const endDate = new Date(+eddateParts[2], eddateParts[1] - 1, +eddateParts[0]); 
      if(startDate>endDate){
        setErrors((prevErrors: any) => ({
          ...prevErrors,
          ["start_date"]: "Start date should not be greater than end date.",
        }));
        errorPresent = true;
      }
    }
    return errorPresent
  };
  return (
    <Box style={modalStyle} className={classes.paper}>
      <Box className={classes.container}>
        <Box className={classes.popupContent}>
          <Box className={classes.header}>
            <Typography className={classes.title}>Create Class</Typography>
            <ClearIcon onClick={handleCloseModal} />
          </Box>
        </Box>
        <Box className={classes.imageLayout}>
          <img
            className={classes.selectedImage}
            src={image ? URL.createObjectURL(image) : classDefault2}
          />
        </Box>
        <form>
          <Grid container spacing={2}>
            <Grid item md={12}>
              <FormControl
                fullWidth
                variant="outlined"
                className={classes.inputDivider}
              >
                <label htmlFor="className" className={classes.inputLabel}>
                  Class Name*
                </label>
                <TextField
                  id="className"
                  name="className"
                  data-test-id={"class-name"}
                  fullWidth
                  placeholder="Enter Class Name"
                  variant="outlined"
                  onChange={handleChange}
                  value={formData.className}
                  InputProps={{
                    className: classes.inputValue,
                  }}
                />
                {Boolean(errors.className) && (
                  <Box className={classes.errorLayout}>
                    <Typography
                      className={classes.error}
                      dangerouslySetInnerHTML={{
                        __html: "Class name is required!",
                      }}
                    />
                  </Box>
                )}
              </FormControl>
            </Grid>
            
            <Grid item md={6}>
              <FormControl fullWidth variant="outlined">
                <label htmlFor="startDate" className={classes.inputLabel}>
                  Start Date
                </label>
                
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <Grid container>
                    <KeyboardDatePicker
                      id="startDate"
                      name="startDate"
                      variant="dialog"
                      data-test-id={"startDatePicker"}
                      format="dd/MM/yyyy"
                      inputVariant="outlined"
                      minDate={new Date()}
                      onChange={(e) => handleDateChange(e, "start_date")}
                      value={initDate("start_date")}
                      labelFunc={() =>
                        formData.start_date || "Select Start Date"
                      }
                      className={
                        initDate("start_date")
                          ? classes.dateFont
                          : classes.dateFontPlaceholder
                      }
                      fullWidth
                    />
                  </Grid>
                </MuiPickersUtilsProvider>
              </FormControl>
              {Boolean(errors.start_date) && (
                  <Box className={classes.errorLayout}>
                    <Typography
                      className={classes.error}
                      dangerouslySetInnerHTML={{
                        __html: errors.start_date,
                      }}
                    />
                  </Box>
                )}
            </Grid>
         
            <Grid item md={6}>
              <FormControl fullWidth variant="outlined">
                <label
                  htmlFor="endDate"
                  className={classes.inputLabel}
                >
                  End Date
                </label>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <Grid container>
                    <KeyboardDatePicker
                      id="endDate"
                      name="endDate"
                      variant="dialog"
                      minDate={initDate("start_date")}
                      data-test-id={"endDatePicker"}
                      format="dd/MM/yyyy"
                      inputVariant="outlined"
                      onChange={(e) => handleDateChange(e, "end_date")}
                      value={initDate("start_date")}
                      labelFunc={() => formData.end_date || "Select End Date"}
                      className={
                        initDate("end_date")
                          ? classes.dateFont
                          : classes.dateFontPlaceholder
                      }
                      fullWidth
                    />
                  </Grid>
                </MuiPickersUtilsProvider>
                {Boolean(errors.end_date) && (
                  <Box className={classes.errorLayout}>
                    <Typography
                      className={classes.error}
                      dangerouslySetInnerHTML={{
                        __html: errors.end_date,
                      }}
                    />
                  </Box>
                )}
              </FormControl>
            </Grid>

            <Grid item md={12}>
              <FormControl
                fullWidth
                variant="outlined"
                className={classes.inputDivider}
              >
                <label htmlFor="attachment" className={classes.inputLabel}>
                  Attachment
                </label>
                <input
                  id="attachment"
                  type="file"
                  accept="image/*"
                  style={{ display: "none" }}
                  ref={fileInputRef}
                  onChange={handleImageUpload}
                />
                <Button
                  id="attachment-1"
                  fullWidth
                  placeholder="Select"
                  variant="outlined"
                  data-test-id="file-select"
                  className={classes.selectBtn}
                  onClick={handleSelectImage}
                >
                  <Box className={classes.selectLayout}>
                    <Box className={classes.selectLayoutLeft}>
                      {image ? (
                        <img
                          src={fileTypePng}
                          style={{ marginRight: "10px" }}
                        />
                      ) : (
                        <></>
                      )}
                      {image ? image.name : "Select"}
                    </Box>
                    {image ? (
                      <img src={fileDelete} onClick={deleteImage} />
                    ) : (
                      <></>
                    )}
                  </Box>
                </Button>
              </FormControl>
            </Grid>
          </Grid>
          <Box />
          <Box className={classes.buttonLayout}>
            <GenericButton
              type="NORMAL"
              label="Cancel"
              handleClick={handleCloseModal}
              customStyle={{ width: "165px" }}
            />
            <GenericButton
              data-test-id="submit-btn"
              type="COLORED"
              label="Create"
              handleClick={handleSubmit}
              customStyle={{ width: "165px" }}
            />
          </Box>
        </form>
      </Box>
    </Box>
  );
};

export default CreateClassModal;
