import InteractivefaqsSharedController from "../../blocks/interactivefaqs/src/InteractivefaqsSharedController";

export enum Roles {
    SUB_ADMIN="Sub-Admins",
    TEACHER="Teacher",
    STUDENT="Student",
}

export interface ListItemData{
    id:string;
    name:string;
    image:string;
}
export interface ClassItem{
    id:string;
    name:string;
    image:string;
    studentCount:number;
    classCode?:string;
}

export interface TeacherState{
    classList : ClassItem[];
    totalStudentCount : number;
    selectedView:string;
    searchInputClassName:string;
}

export enum TEACHER_DASHBOARD_VIEWS{
    DASHBOARD="Dashboard",
}
export enum TEACHER_COURSE_CREATION_VIEWS{
    COURESES="COURSES",
}
export interface Student{
    id:string;
    fullName:string;
    email:string;
    password:string;
    classes:any[];
    creationDate:string;
    profileStatus:string;
    phone?:string;
    gender?:string;
    birthDate?:string
}
export enum STUDENT_DASHBOARD_VIEWS{
    DASHBOARD="Dashboard",
}

export interface Lesson{
    id:string;
    lessonName:string;
    lessonCode:string;
}
export interface StudentLesson{
    id:string;
    name:string;
    code:string;
    status:string;
    live:string;
    stages:number;
}
export interface Quiz{
    id:string;
    quizName:string;
    quizCode:string;
}
export interface Assignment{
    id:string;
    assignmentName:string;
    assignmentStatus:string;
}


export interface MyClassesState{
    selectedView:string,
    classList:ClassItem[],
    searchInputClassName:string;
    searchInputLessonName:string;
    searchInputQuizName:string;
    searchInputAssignmentName:string;
    searchInputStudentLessonName:string;
    studentList:Student[];
    studentDetails:any;
    selectedClass:string;
    selectedStudent:Student;
    formClassList:any[];
    lessonList:Lesson[];
    studentLessonData: StudentLesson[];
    teacherId:string;
    assignmentList:Assignment[],
    quizList:Quiz[],
}
export enum MY_CLASSES_VIEWS{
    MY_CLASSES="My Classes",
    CLASS_DETAILS="class details",
    JOIN_LIVE_SESSION="Join Live Session",
    STUDENT_LIST="Students",
    ADD_STUDENT="ADD_STUDENT",
    CLASS_LIBRARY="Class Library",
    REPORT="Reports",
    ASSIGNMENTS = "Assignments"
}

export enum CLASS_OPTIONS{
    LESSONS="Lessons",
    ASSIGNMENTS="Assignments",
    QUIZZES="Quizzes"
}

export enum COURSES_PAGES{
    LESSON="LESSON",
    ASSIGNMENT="ASSIGNMENT",
    QUIZ="QUIZ"
}

export enum ACTIONS{
    ADD_TEXT="add_text",
    KWL_CHART="kwl_chart",
    T_CHART="t_chart",
    WH_CHART="wh_chart",
    SEED_DISCUSSION="seed_discussion",
    VENN_DIAGRAM="venn_diagram",
    MULTIMEDIA="add_multimedia",
    ADD_MCQ="ADD MCQ",
    ADD_POLL="ADD POLL",
    ADD_FAIL_MCQ = "ADD FAIL MCQ",
    ADD_FAIL_POLL = "ADD FAIL POLL",
    ADD_WORD_CLOUD="add_word_cloud",
    MCQ="MCQ",
    OPEN_ENDED_QUESTION="open_ended_question",
    ADD_MATCHING_Q="matching_question",
    POLLING_Q="polling_question"
}

export enum CHAT{
    PARTICIPANTS="Participants",
    QUESTION="Question",
    COMMENTS="Comments"
}

export interface Stage{
    id:number;
    title?: string;
    count?: number;
}

export enum TEACHER_Library_VIEWS{
    LIBRARY="Library",
}

export enum REPORT_OPTIONS{
    SUMMARTY_REPORT="Summary Report",
    DETAILED_REPORT="Detailed Report",
}

export enum QuestionHeaders{
    KWL_CHART="KWL Chart Question",
    T_CHART="T-Chart Question",
    WH_CHART="WH Chart Question",
    SEED_DISCUSSION="Seed Discussion Question",
    VENN_DIAGRAM="Venn Diagram Question",
    ADD_WORD_CLOUD="Word Cloud Question",
    MCQ="Multiple Choice Question",
    OPEN_ENDED_QUESTION="Open-Ended Question",
    ADD_MATCHING_Q="Matching Question",
    POLLING_Q="Polling Question"
}

export const getQuestionHeader = (type:ACTIONS)=>{
    switch(type){
        case ACTIONS.MCQ: case ACTIONS.ADD_MCQ: return QuestionHeaders.MCQ;
        case ACTIONS.ADD_WORD_CLOUD: return QuestionHeaders.ADD_WORD_CLOUD
        case ACTIONS.KWL_CHART: return QuestionHeaders.KWL_CHART
        case ACTIONS.WH_CHART: return QuestionHeaders.WH_CHART
        case ACTIONS.SEED_DISCUSSION: return QuestionHeaders.SEED_DISCUSSION
        case ACTIONS.T_CHART: return QuestionHeaders.T_CHART
        case ACTIONS.VENN_DIAGRAM: return QuestionHeaders.VENN_DIAGRAM
        case ACTIONS.OPEN_ENDED_QUESTION: return QuestionHeaders.OPEN_ENDED_QUESTION
        case ACTIONS.ADD_MATCHING_Q: return QuestionHeaders.ADD_MATCHING_Q
        case ACTIONS.POLLING_Q: case ACTIONS.ADD_POLL: return QuestionHeaders.POLLING_Q
        default : return ""
    }
}
export const getStageType = (stageType:string)=>{
    switch(stageType){
        case "add_text": return "Text"
        case "kwl_chart": return "KWL Chart"
        case "t_chart": return "T Chart"
        case "wh_chart": return "WH Chart"
        case "seed_discussion": return "Seed"
        case "venn_diagram": return  "Venn"
        case "add_multimedia": return "Multimmedia"
        case "ADD MCQ": case "MCQ": return "MCQ"
        case "ADD POLL": case "polling_question": return "Poll"
        case "add_word_cloud": return "Word Cloud"
        case "open_ended_question": return "Open Ended"
        case "matching_question": return "Matching"
        default:
        return ""
    }
}