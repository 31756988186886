import React,{useState} from "react";
import { Box, Typography, Grid,ListItemText } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { studentCount } from "../../assets";
import { MY_CLASSES_VIEWS } from "../../../../../components/src/types";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { StyledMenu,StyledMenuItem } from "../../../../../components/src/StyledMenu.web";
import CustomDeleteModal from "../../../../../components/src/CustomDeleteModal.web";
import CommonModal from "../../../../../components/src/CommonModal.web";


interface Data {
  id: string;
  image: string;
  name: string;
  studentCount: string;
  classCode:number;
}

interface ClassListProps {
  dataList: Data[];
  handleClick?: any;
  isTeacher?:boolean;
  showMore?:boolean;
  deleteClassCallback?:(id:string)=>void;
  deleteClassErrorMsg?:string;
  showError?:boolean;
  handleCloseError?:(id:string)=>void;
}

interface DeleteProps {
  id:string;
  itemType:string;
  name:string
}

const useStyles = makeStyles(() => ({
  noRecords: {
    fontFamily: "Poppins",
    textAlign: "center",
    paddingTop: "35px",
  },
  listItemBox: {
    position:"relative",
    display: "flex",
    justifyContent: "start",
    alignItems: "center",
    border: "1px solid rgba(149, 157, 165, 0.2)",
    borderRadius: "10px",
    margin: "20px 10px",
    padding: "15px",
    minHeight: "135px",
    boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
    "&:hover": {
      background: "#ebf8fe",
      borderColor: "#3093DDFF",
      color: "#121212",
      cursor:"pointer"
    },
    "@media (max-width: 600px)": {
      flexDirection: 'column'
     },
  },
  countBar: {
    display: "flex",
    marginTop: "20px",
    "@media (max-width: 600px)": {
      marginTop: '6px',
      marginRight: '9px'
     },
  },
  listItemFont: {
    fontFamily: "Poppins",
    fontSize: "20px",
    color: "#282829",
    fontWeight: 600,
  },
  countBarText: {
    fontFamily: "Poppins",
    fontSize: "14px",
    color: "#2B3D7D",
    fontWeight: 400,
  },
  listItemImage: {
    width: "100%",
    height: "100%",
  },
  countBarImage: {
    width: "24px",
    height: "24px",
    borderRadius: "50%",
    marginRight: "10px",
  },
  imageBox: {
    width: "90px",
    height: "90px",
    border: "1px solid rgba(149, 157, 165, 0.2)",
    marginRight: "10px",
    padding: "8px",
    borderRadius: "10px",
    backgroundColor: "#fff",
  },
  classDetails: {
    "@media (max-width: 600px)": {
      display:"flex",
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems:'center',
      marginTop:12
     },
  },
  classCode: {
    "@media (max-width: 600px)": {
      marginTop:'2px'
     },
  },
  deleteBox:{
    position:"absolute",
    right: "10px",
    top: "10px",
    width:" 30px",
    height: "30px",
    display: "flex",
  }
}));

const contextMenu = (anchorEl:any,handleCloseContextMenu:()=>void,openContextMenu:boolean, handleOpenDeleteModal:()=>void) => {
  return (
    <StyledMenu
      id="customized-menu"
      data-test-id="customized-menu"
      anchorEl={anchorEl}
      open={openContextMenu}
      onClose={handleCloseContextMenu}
    >
      <StyledMenuItem>
        <ListItemText
          data-test-id="delete-menu"
          primary="Delete Class"
          onClick={(e) => {
            handleCloseContextMenu();
            handleOpenDeleteModal();
          }}
          color="#48484A"
        />
      </StyledMenuItem>
    </StyledMenu>
  )
}

const ClassList = (props: ClassListProps) => {
  const { dataList, handleClick, showMore,deleteClassCallback,deleteClassErrorMsg,showError,handleCloseError } = props;
  const classes = useStyles();
  const [openContextMenu,setOpenContextMenu] = useState(false);
  const [showDelete,setShowDelete] = useState(false);
  const [anchorEl,setAnchorEl] = useState<any>(null);
  const [classData,setClassData] = useState<DeleteProps>({id:"",name:"",itemType:"class"})
  const handleCloseContextMenu = () => {
    setOpenContextMenu(false)
  }

  const handleOpenDeleteModal = () => {
    handleCloseContextMenu()
    setShowDelete(true)
  }

  const closeDeleteModal = () => {
    setShowDelete(false)
  }

  const deleteClass = (id:string,type:string) => {
    deleteClassCallback && deleteClassCallback(id)
  }

  return (
    <Box>
      {dataList.length === 0 ? (
        <Typography className={classes.noRecords}>No records found</Typography>
      ) : (
        <>
        <Grid container>
          {dataList.map((data: Data) => (
            <Grid item md={4} sm={6} xs={12} key={data.id}>
             
              <Box
                className={classes.listItemBox}
                onClick={handleClick? (e) =>
                  handleClick(e, {
                    id: data.id,
                    view: MY_CLASSES_VIEWS.MY_CLASSES,
                    breadcrumbDisplayName: "My Classes",
                    destination: data.name,
                  }):undefined
                }
                data-test-id={`class-${data.id}`}
              >
              {showMore && 
               <Box className={classes.deleteBox} data-test-id="deleteClass">
                  <MoreVertIcon 
                      onClick={(e)=>{
                        e?.stopPropagation();
                        setAnchorEl(e?.currentTarget);
                        setOpenContextMenu(true);
                        setClassData({...classData,id:data.id,name:data.name})
                      }}
                      data-test-id={`more-${data.id}`}
                  />
               </Box>
               }
                <Box className={classes.imageBox}>
                  <img className={classes.listItemImage} src={data.image} />
                </Box>
                <Box className={classes.classDetails}>
                  <Typography className={classes.listItemFont}>
                    {data.name}
                  </Typography>
                  <Box className={classes.countBar}>
                    <img className={classes.countBarImage} src={studentCount} />
                    <Typography className={classes.countBarText}>
                      Student Count - {data.studentCount}
                    </Typography>
                  </Box>
                 {props.isTeacher && <Box className={classes.classCode} >
                    <Typography className={classes.countBarText}>
                      Class Code - {data.classCode}
                    </Typography>
                  </Box>}
                </Box>
              </Box>
            </Grid>
          ))}
        </Grid>
        {contextMenu(anchorEl,handleCloseContextMenu,openContextMenu,handleOpenDeleteModal)}
        <CustomDeleteModal 
          open={showDelete}
          handleClose={closeDeleteModal}
          contextMenuData={classData}
          callback={deleteClass}
          deleteMsg={"Are you sure you want to delete this"}
          confirmMsg={`The class "${classData.name}" will be deleted. All teacher and student records including the class library will be lost. Please download the lessons from your class library to your local device or move them to your Google Drive account before proceeding.`}
        />
        <CommonModal 
          open={showError}
          handleClose={handleCloseError}
          errorMsg={deleteClassErrorMsg}
        />
        </>
      )}
    </Box>
  );
};

export default ClassList;
